import axios, { HeadersDefaults } from "axios";
import { ID_LOCAL_STORAGE_ACCESS_TOKEN } from "../../constants/cookie-local-storage";
import { RoutesNames } from "constants/routes";
import { BASE_URL_DEV, BASE_URL_TEST, BASE_URL_PROD } from "constants/endpoint";

export interface AxiosCommonHeaderProperties extends HeadersDefaults {
  Authorization: string;
}

let baseURL = "";
if (process.env.REACT_APP_NODE_ENV === "development") {
  baseURL = BASE_URL_DEV;
} else if (process.env.REACT_APP_NODE_ENV === "test") {
  baseURL = BASE_URL_TEST;
} else {
  baseURL = BASE_URL_PROD;
}

axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;
export const api = axios.create({ baseURL });

api.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem(ID_LOCAL_STORAGE_ACCESS_TOKEN);

    config.withCredentials = true;
    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";
    return config;
  },
  async (error) => {
    await Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log("ERROR: ");

    if (error?.response?.status === 401) {
      localStorage.clear();
      window.location.replace(RoutesNames.COMMON.LOGIN);
    }

    return await Promise.reject(error);
  }
);

export default api;
