import React, { useState } from "react";

import {
  Box,
  Button,
  Grid,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { HeaderTitle, StepperVertical } from "components";
import { grey } from "@mui/material/colors";
import { TbClipboardList, TbCloudDownload } from "react-icons/tb";
import ReportDemo from "../../../assets/images/report-demo.jpg";

const ReportStep = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState<number>(1);
  const [showStep, setShowStep] = useState<boolean>(false);
  interface Step {
    label: string;
    title: string;
    description: React.ReactNode;
  }

  const StepDescription = () => (
    <Stack>
      <Typography variant="h6" marginBottom={4} color='text.primary'>
        Relatório em Progresso
      </Typography>

      <Typography variant="body2" marginBottom={2} color='text.secondary'>
        Seu relatório será disponibilizado aqui quando estiver pronto. Equanto
        isso, você pode acompanhar o progresso ao lado.
      </Typography>

      <Typography variant="body2" marginBottom={2} color='text.secondary'>
        Tudo começa com sua companhia dispobilizando os dados em quaisquer
        formatos na aba de Dados, no menu principal.
      </Typography>

      <Typography variant="body2" marginBottom={2} color='text.secondary'>
        Uma vez os dados disponiveis, os nossos cientistas seguem a diante com
        as demais etapas do processo.
      </Typography>

      <Stack>
        <Typography variant="body2" color='text.secondary' gutterBottom>
          A duração de cada etapa depende de:
        </Typography>
        <ol style={{ paddingLeft: 24 }}>
          <Typography variant="subtitle2" color='text.secondary'>
            <li>Volume de dados</li>
          </Typography>
          <Typography variant="subtitle2" color='text.secondary'>
            <li>Complexidade dos dados</li>
          </Typography>
          <Typography variant="subtitle2" color='text.secondary'>
            <li>Complexidade de pre-processamento necessários</li>
          </Typography>
        </ol>
      </Stack>

      <Typography variant="body2" marginTop={2} color='text.secondary'>
        Para tirar quaisquer dúvidas, entre em{" "}
        <Link underline="hover">contato com nosso time de suporte</Link>
      </Typography>
    </Stack>
  );

  const steps: Step[] = [
    {
      label: "Submissão dos dados",
      title: "Relatório em Progresso",
      description: null,
    },
    {
      label: "Entendimento do negócio",
      title: "",
      description: <StepDescription />,
    },
    {
      label: "Entendimento dos dados",
      title: "",
      description: null,
    },
    {
      label: "Processamento de Dados",
      title: "",
      description: null,
    },
    {
      label: "Modelagem",
      title: "",
      description: null,
    },
    {
      label: "Geração de Relatório",
      title: "",
      description: null,
    },
  ];

  return (
    <>
      <HeaderTitle title="Relatório">
        {showStep && (
          <Stack
            marginRight={2}
            flexDirection="row"
            alignItems="center"
            onClick={() => setShowStep(false)}
            sx={{ cursor: "pointer" }}
          >
            <TbClipboardList size={20} color={theme.palette.primary.main} />
            <Typography variant="body2" marginLeft={1} color='text.primary'>
              Ver relatório
            </Typography>
          </Stack>
        )}

        {!showStep && (
          <Stack
            marginRight={2}
            flexDirection="row"
            alignItems="center"
            onClick={() => setShowStep(true)}
            sx={{ cursor: "pointer" }}
          >
            <TbClipboardList size={20} color={theme.palette.primary.main} />
            <Typography variant="body2" marginLeft={1} color='text.primary'>
              Ver processo
            </Typography>
          </Stack>
        )}

        {/* <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={showStep}
                  onChange={handleShowStep}
                />
              }
              label="Ver processo"
            />
          </FormGroup> */}
      </HeaderTitle>

      {!showStep && (
        <Stack marginBottom={10}>
          <Stack
            sx={{
              overflowX: "hidden",
              overflow: "auto",
              display: "flex",
              maxHeight: 800,
              paddingRight: 2,
              flex: 1,
            }}
          >
            <img
              src={ReportDemo}
              alt="Report"
              style={{ borderRadius: 8, border: `1px solid ${grey[200]}` }}
            />
          </Stack>
          <Stack marginTop={2}>
            <Button
              size="large"
              fullWidth
              variant="contained"
              startIcon={<TbCloudDownload />}
            >
              Baixar relatório
            </Button>
          </Stack>
        </Stack>
      )}

      {showStep && (
        <Stack sx={{ backgroundColor: theme.palette.background.paper, borderRadius: 2 }}>
          <Grid container justifyContent="center" padding={5}>
            <Grid item xs={4}>
              <StepperVertical
                steps={steps.map((step) => step.label)}
                activeStep={activeStep}
              />
            </Grid>
            <Grid item xs={8}>
              {steps[activeStep].description}
            </Grid>
          </Grid>
        </Stack>
      )}
    </>
  );
};

export const Report = () => (
  <Grid container spacing={2} justifyContent="center">
    <Grid item xs={12}>
      <ReportStep />
    </Grid>
  </Grid>
);
