export const RoutesNames = {
  COMMON: {
    HOME: '/',
    LOGIN: '/login',
    NO_ACCESS: '/no-access',
    NOT_FOUND: '/not-found',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password/:token',
    ENABLE_ACCOUNT: '/enable-account/:token',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
  },
  ADMIN: {
    HOME: '/admin',
    PROFILE: '/admin-profile',
  },
  FRANCHISOR: {
    HOME: '/franchisor',
    DATA: '/franchisor-data',
    REPORT: '/franchisor-report',
    PROFILE: '/franchisor-profile',
  },
  ASSOCIATE: {
    HOME: '/associate',
    PROFILE: '/associate-profile',
    QUIZ: '/associate-quiz',
  },
  CANDIDATE: {
    HOME: '/candidate',
    PROFILE: '/candidate-profile',
    QUIZ: '/candidate-quiz',
  },
  INTERESTED: {
    PROFILE: '/interested-profile',
  },
}
