import * as yup from "yup";

export const validationSchemaLogin = yup.object({
  email: yup
    .string()
    .email("Digite um e-mail válido")
    .required("Campo obrigatório"),
  password: yup.string().required("Campo obrigatório"),
});

export const validationSchemaSignup = yup.object({
  fullname: yup
    .string()
    .required("Campo obrigatório")
    .min(10, "O nome deve conter ao menos 10 caracteres"),

  email: yup
    .string()
    .email("Digite um e-mail válido")
    .required("Campo obrigatório"),

  password: yup
    .string()
    .min(6, "A senha deve conter no mínimo 6 caracteres.")
    .required("Campo obrigatório"),

  confirm_password: yup
    .string()
    .required("Campo obrigatório")
    .oneOf([yup.ref("password")], "As senha não são iguais."),

  type_id: yup.string().required("Campo obrigatório"),
});
