import React, { useCallback, useEffect } from "react";

import Stack from "@mui/material/Stack";

import { Grid } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import Congratulation from "../../../../assets/lottie/congratulation.json";
import { QuestionTitle } from "../question-title";
import { useGlobalState } from "context";

export function StepFinished() {
  const [{ user }, dispatch] = useGlobalState();

  const onUpdateUserContext = useCallback(() => {
    dispatch({
      type: "SET_USER",
      data: {
        ...user!,
        quiz_is_finished: true,
      },
    });
  }, [dispatch, user]);

  useEffect(() => {
    setTimeout(() => {
      onUpdateUserContext();
    }, 5000);
  }, [dispatch, onUpdateUserContext, user]);

  return (
    <Stack marginTop={4}>
      <QuestionTitle
        title="Respostas Enviadas"
        subtitle="Hoje você deu um passo muito importante na sua jornada"
        isTitleCentered
        onBackClicked={onUpdateUserContext}
      />

      <Grid container justifyContent="center">
        <Grid item md={12} xs={12}>
          <Player
            autoplay
            src={Congratulation}
            keepLastFrame
            speed={0.8}
            style={{
              height: "350px",
              width: "100%",
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
