// ERRO 404 AO DAR F5
// https://dhiwise.com/post/a-comprehensive-guide-to-fixing-react-router-refresh-404

import React from "react";
import { Routes, HashRouter, Route, BrowserRouter } from "react-router-dom";

import { useGlobalState } from "../context";
import * as PagesCommon from "../pages";
import * as PagesFranchisor from "../pages/area-franchisor";
import * as PagesAssociate from "../pages/area-associate";
import * as PagesAdmin from "../pages/area-admin";

import {
  LoggedRoute,
  PrivateRouteAdmin,
  PrivateRouteAssociate,
  PrivateRouteFranchisor,
  PrivateRouteCommon,
  PrivateRouteCandidate,
  PublicRoute,
} from "./manager-route";
import { LayoutAdmin, LayoutAssociate, LayoutCandidate } from "../layout";

import { LayoutFranchisor } from "../layout/franchisor";
import { RoutesNames } from "constants/routes";

export const AllRoutes = () => {
  return (
    <HashRouter>
      <Routes>

        <Route element={<PublicRoute />}>
          <Route
            path={RoutesNames.COMMON.HOME}
            Component={PagesCommon.Home}
          />
        </Route>


        {/* COMMON */}
        {/* <Route
          path={RoutesNames.COMMON.HOME}
          Component={PagesCommon.Home}
        /> */}
        <Route
          path={RoutesNames.COMMON.ENABLE_ACCOUNT}
          Component={PagesCommon.EnableAccount}
        />
        <Route
          path={RoutesNames.COMMON.TERMS_AND_CONDITIONS}
          Component={PagesCommon.TermsAndConditions}
        />

        <Route element={<PrivateRouteCommon />}>
          {/* <Route path="/" Component={null} /> */}
          <Route
            path={RoutesNames.COMMON.NO_ACCESS}
            Component={PagesCommon.NoAccess}
          />
        </Route>

        {/* AUTH */}
        <Route element={<LoggedRoute />}>
          <Route
            path={RoutesNames.COMMON.LOGIN}
            Component={PagesCommon.Auth}
          />
          <Route
            path={RoutesNames.COMMON.FORGOT_PASSWORD}
            Component={PagesCommon.ForgotPassword}
          />
          <Route
            path={RoutesNames.COMMON.RESET_PASSWORD}
            Component={PagesCommon.ResetPassword}
          />
        </Route>

        {/* ADMIN */}
        <Route element={<PrivateRouteAdmin />}>
          <Route element={<LayoutAdmin />}>
            <Route path={RoutesNames.ADMIN.HOME} Component={PagesAdmin.Home} />

            <Route
              path={RoutesNames.ADMIN.PROFILE}
              Component={PagesCommon.Profile}
            />
          </Route>
        </Route>

        {/* FRANCHISOR */}
        <Route element={<PrivateRouteFranchisor />}>
          <Route element={<LayoutFranchisor />}>
            <Route
              path={RoutesNames.FRANCHISOR.DATA}
              Component={PagesFranchisor.Data}
            />
            <Route
              path={RoutesNames.FRANCHISOR.REPORT}
              Component={PagesFranchisor.Report}
            />
            <Route
              path={RoutesNames.FRANCHISOR.PROFILE}
              Component={PagesCommon.Profile}
            />
          </Route>
        </Route>

        {/* ASSOCIATE */}
        <Route element={<PrivateRouteAssociate />}>
          <Route element={<LayoutAssociate />}>
            <Route
              path={RoutesNames.ASSOCIATE.PROFILE}
              Component={PagesCommon.Profile}
            />
            <Route
              path={RoutesNames.ASSOCIATE.QUIZ}
              Component={PagesAssociate.Quiz}
            />
          </Route>
        </Route>

        {/* CANDIDATE */}
        <Route element={<PrivateRouteCandidate />}>
          <Route element={<LayoutCandidate />}>
            <Route
              path={RoutesNames.CANDIDATE.PROFILE}
              Component={PagesCommon.Profile}
            />
            <Route
              path={RoutesNames.CANDIDATE.QUIZ}
              Component={PagesAssociate.Quiz}
            />
          </Route>
        </Route>
      </Routes>
    </HashRouter>
  );
};

export const Router = () => {
  const [{ isLoadingApp }] = useGlobalState();
  return isLoadingApp ? <div></div> : <AllRoutes />;
};
