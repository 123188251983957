import * as yup from "yup";

export const validationSchemaPassword = yup.object({
  password: yup.string().required("Campo obrigatório"),

  new_password: yup
    .string()
    .min(6, "A senha deve conter no mínimo 6 caracteres.")
    .required("Campo obrigatório"),

  confirm_new_password: yup
    .string()
    .required("Campo obrigatório")
    .oneOf([yup.ref("new_password")], "As senha não são iguais."),
});

export const validationSchemaTwoFactorPhone = yup.object({
  phone: yup.string().required("Campo obrigatório"),
});

export const validationSchemaTwoFactorCode = yup.object({
  code: yup.string().required("Campo obrigatório"),
});
