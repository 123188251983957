import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import * as S from "./style";

export const LayoutAdmin = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Container>
      <h1>Layout Admin</h1>
      <Outlet />
    </S.Container>
  );
};
