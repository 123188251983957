import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useMutation } from "react-query";
import { Form, FormikProvider, useFormik } from "formik";

import { Footer, FormikTextField } from "../../components";

import { ApiService } from "../../services";
import LoadingButton from "@mui/lab/LoadingButton";
import { AxiosError } from "axios";
import { validationSchema } from "./validationSchema";
import { toast } from "react-toastify";
import { RoutesNames } from "constants/routes";
import { IMAGES } from "constants/images";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { useGlobalState } from "context";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

interface MutationProps {
  email: string;
}

const INITIAL_VALUES: MutationProps = {
  email: "",
};

export const ForgotPassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [{ themeMode }] = useGlobalState();
  const recaptchaRef = React.createRef<any>();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const isValidGoogleRecaptcha = async () => {
    return true;

    // if (process.env.REACT_APP_NODE_ENV === "development") return true;

    // const responseToken = await recaptchaRef.current.executeAsync();

    // const responseGoogleReCaptcha = await ApiService.Auth.googleReCaptcha({
    //   responseToken,
    // });

    // if (
    //   !responseGoogleReCaptcha.success ||
    //   responseGoogleReCaptcha.score <= 0.4
    // ) {
    //   return false;
    // }
    // return true;
  };

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidRecaptcha = await isValidGoogleRecaptcha();

      if (!isValidRecaptcha) {
        toast.error("Login bloqueado! Por favor, entre em contato.", {
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }

      mutationForgoPassword.mutate(values);
    },
  });

  const mutationForgoPassword = useMutation<
    undefined,
    AxiosError,
    MutationProps
  >("forgotPassword", (data) => ApiService.Auth.forgotPassword(data), {
    onSuccess: () => {
      formik.resetForm();
      toast.success(
        "Instruções enviadas ao email. Verifique sua lixeira eletrônica",
        {
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    },
    onError: () => {
      toast.error("Erro inesperado. Por favor, tente novamente.", {
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    },
  });

  return (
    <Stack
      style={{
        width: "100%",
        padding: 40,
        minHeight: "100vh",
        backgroundImage: `url(${
          themeMode === "light" ? IMAGES.BG1_LIGHT : IMAGES.BG1_DARK
        })`,
        backgroundSize: `cover`,
        backgroundPosition: `0px 0px`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <Stack display="flex" alignItems="center">
        <Stack paddingBottom={6} display="flex" alignItems="center">
          {themeMode === "light" ? (
            <img
              alt="logo"
              src={IMAGES.LOGO_LIGHT_IMG}
              style={{ width: isXs ? 290 : 360, height: isXs ? 74 : 94 }}
            />
          ) : (
            <img
              alt="logo"
              src={IMAGES.LOGO_DARK_IMG}
              style={{ width: isXs ? 290 : 360, height: isXs ? 74 : 94 }}
            />
          )}

          <Box mb={5} />

          <Stack
            direction="column"
            padding={5}
            borderRadius={2}
            sx={{
              backgroundColor: theme.palette.background.paper,
              display: "flex",
              flex: 1,
              border: `1px solid ${theme.palette.borderColor.main}`,
              width: {
                xs: 360,
                sm: 480,
              },
            }}
          >
            <Typography variant="h6" textAlign="center" color="text.primary">
              Redefinir senha
            </Typography>

            <FormikProvider value={formik}>
              <Form>
                <Stack spacing={1} paddingTop={2}>
                  <FormikTextField name="email" label="Digite seu email" />

                  <Typography variant="body2" color="text.secondary">
                    Você receberá instruções nesse email
                  </Typography>

                  <Stack spacing={2} paddingTop={1}>
                    <Button onClick={() => navigate(RoutesNames.COMMON.LOGIN)}>
                      Fazer login
                    </Button>

                    <Stack paddingTop={2}>
                      <LoadingButton
                        type="submit"
                        loading={mutationForgoPassword.isLoading}
                        loadingPosition="center"
                        variant="contained"
                        size="large"
                      >
                        Redefinir senha
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Stack>
              </Form>
            </FormikProvider>
          </Stack>
        </Stack>
        <Box mt={10} />
        <Footer />
      </Stack>

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY_SITE || ""}
      />
    </Stack>
  );
};
