import React, { useState } from "react";
import { TbLock, TbPencilMinus, TbUser } from "react-icons/tb";

import { Box, Grid, Stack } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material";

import { Security } from "./security";
import { Data } from "./data";
import { grey } from "@mui/material/colors";
import { useGlobalState } from "context";
import { EnumUserTypeBr } from "constants/user-type";
import { IMAGES } from "constants/images";

export const Profile = () => {
  const theme = useTheme();
  const [{ user }] = useGlobalState();
  const [currentTab, setCurrentTab] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Grid container justifyContent="center" paddingX={4}>
      <Grid item xs={12} md={10}>
        {["ASSOCIATE", "CANDIDATE", "INTERESTED"].includes(
          user?.user_type || ""
        ) && (
            <Stack sx={{ width: "100%" }} spacing={2} mb={2}>
              <Alert severity="info">
                Obrigado por participar da pesquisa. Aguarde o contato de um de
                nossos consultores.
              </Alert>
            </Stack>
          )}

        <Card>
          <CardMedia
            sx={{
              height: {
                xs: 140,
                md: 200,
                backgroundColor: theme.palette.background.paper,
              },
              position: "relative",
            }}
          // image={Perfilcapa}
          >
            <Stack
              sx={{
                display: "flex",
                position: "absolute",
                flexDirection: "row",
                left: 24,

                bottom: {
                  xs: 24,
                  md: -24,
                },
              }}
            >
              <Avatar
                src={IMAGES.ACCOUNT}
                sx={{
                  width: { xs: 90, md: 128 },
                  height: { xs: 90, md: 128 },
                }}
              />
              <Box mr={2} />
              <Stack
                sx={{
                  justifyContent: {
                    xs: "flex-end",
                    md: "flex-start",
                  },
                }}
              >
                <Box mt={4} />
                <Typography
                  sx={{
                    typography: {
                      xs: "h5",
                      md: "h4",
                    },
                  }}
                >
                  {user?.firstname || user?.fullname}
                </Typography>
                <Typography variant="subtitle2" color="secondary">
                  {EnumUserTypeBr[user?.user_type!]}
                </Typography>
              </Stack>
            </Stack>
          </CardMedia>
          <CardContent
            sx={{
              height: 48,
              padding: 0,
            }}
          >
            <Stack
              sx={{
                alignItems: {
                  xs: "center",
                  md: "flex-end",
                },
              }}
            >
              <Tabs
                value={currentTab}
                onChange={handleChange}
                selectionFollowsFocus
                style={{
                  maxHeight: 48,
                }}
                variant="scrollable"
              >
                <Tab
                  icon={<TbPencilMinus size={24} />}
                  iconPosition="start"
                  label={
                    <Typography
                      sx={{
                        typography: {
                          xs: "caption",
                          md: "body1",
                        },
                      }}
                    >
                      Dados
                    </Typography>
                  }
                  sx={{ minHeight: 48, textTransform: "capitalize" }}
                />
                <Tab
                  icon={<TbLock size={24} />}
                  iconPosition="start"
                  label={
                    <Typography
                      sx={{
                        typography: {
                          xs: "caption",
                          md: "body1",
                        },
                      }}
                    >
                      Segurança
                    </Typography>
                  }
                  sx={{ minHeight: 48, textTransform: "capitalize" }}
                />
              </Tabs>
            </Stack>
          </CardContent>
        </Card>

        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            {currentTab === 0 && <Data />}
            {currentTab === 1 && <Security />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
