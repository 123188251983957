import React from "react";
import { Typography, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import { grey } from "@mui/material/colors";

interface FooterProps extends Partial<React.PropsWithChildren> {
  title: string;
  subtitle?: string;
}
export const HeaderTitle = ({ title, subtitle, children }: FooterProps) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        paddingY: 2,
        marginBottom: 3,
        borderBottom: `1px solid ${theme.palette.borderColor.main}`,
        flexDirection: "row",
      }}
    >
      <Stack flex={1} justifyContent="center">
        <Typography
          color='text.primary'
          sx={{
            typography: {
              xs: "subtitle1",
              md: "h5",
            },
          }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="body2" color="text.secondary">
            {title}
          </Typography>
        )}
      </Stack>
      {children}
    </Stack>
  );
};
