import { createGlobalStyle } from 'styled-components'

interface GlobalStyleProps {
  backgroundColor: string
}
const GlobalStyle = createGlobalStyle<GlobalStyleProps>` 
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body, #root {  
    height: 100%;
  }
  body {
    text-rendering: optimizeLegibility !important;
    font-family: 'Sora', 'Times New Roman', Times, serif;
    -webkit-font-smoothing: antialiased;
    background: ${({ backgroundColor }) => backgroundColor};
  }
`

export default GlobalStyle
