import React, { useMemo } from "react";
import { useField } from "formik";

import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Grid, Radio, SxProps, Theme, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { grey } from "@mui/material/colors";
import { useMediaQuery } from "@mui/material";

export interface FormikRadioImageGroupItem {
  value: string;
  title: string;
  imageUrl: string;
}

type FormikRadioImageGroupProps = {
  name: string;
  label?: string;
  sxRadioImageGroup?: SxProps<Theme>;
  sxRadioImage?: SxProps<Theme>;
  radioImageList: FormikRadioImageGroupItem[];
} & RadioGroupProps;
export const FormikRadioImageGroup = ({
  name,
  label,
  sxRadioImageGroup,
  sxRadioImage,
  radioImageList,
  ...props
}: FormikRadioImageGroupProps) => {
  const [
    field,
    meta,
    // helpers
  ] = useField(name);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const imgHeight: Number = useMemo(() => {
    if (isXs) return 180;
    if (isSm) return 200;
    if (isMd) return 200;

    return 0;
  }, [isMd, isSm, isXs]);

  const RenderImage = (isActive: boolean, imageUrl: string) => {
    return (
      <Stack
        sx={{
          padding: 0.5,
          borderRadius: "16px",
          border: isActive
            ? `2px dashed ${theme.palette.primary.main}`
            : `2px dashed ${grey[200]}`,
        }}
      >
        <Stack width="100%" height={`${imgHeight}px`}>
          <img
            src={imageUrl}
            alt="Quiz"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "16px",
            }}
          />
        </Stack>
      </Stack>
    );
  };

  return (
    <FormControl>
      {label && (
        <Stack marginBottom={1}>
          <FormLabel
            sx={{ color: ` ${theme.palette.text.primary} !important` }}
            id={`formlabel-${name}`}
          >
            {label}
          </FormLabel>
        </Stack>
      )}

      <RadioGroup {...field} {...props} name={field.name}>
        <Grid container spacing={1}>
          {radioImageList.map((item) => (
            <Grid item xs={12} sm={6} md={4} xl={4}>
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={
                  <Radio
                    disableRipple
                    checkedIcon={RenderImage(
                      field.value === item.value,
                      item.imageUrl
                    )}
                    icon={RenderImage(
                      field.value === item.value,
                      item.imageUrl
                    )}
                    sx={{
                      padding: 0,
                      margin: 0,
                      width: "100%",
                    }}
                  />
                }
                label={item.title}
                labelPlacement="bottom"
                sx={{
                  width: "100%",
                  margin: 0,
                  padding: 0,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>

      {meta.touched && Boolean(meta.error) && (
        <FormHelperText sx={{ color: "#fe494cff !important" }}>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};
