import React from "react";
import { useField } from "formik";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Stack } from "@mui/system";

import { styled } from "@mui/material/styles";
import Rating, { RatingProps, IconContainerProps } from "@mui/material/Rating";
import { useTheme } from "@mui/material";

import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";

type FormikFormikRatingFace = {
  name: string;
  label?: string;
} & RatingProps;

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.text.secondary,
  },
  "& .MuiSvgIcon-root > label": {
    backgroundColor: "red",
  },
}));

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" sx={{ fontSize: 52 }} />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" sx={{ fontSize: 52 }} />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" sx={{ fontSize: 52 }} />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" sx={{ fontSize: 52 }} />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" sx={{ fontSize: 52 }} />,
    label: "Very Satisfied",
  },
};

export const FormikRatingFace = ({
  name,
  label,
  ...props
}: FormikFormikRatingFace) => {
  const [
    field,
    meta,
    // helpers
  ] = useField(name);
  const theme = useTheme();

  function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  return (
    <FormControl>
      {label && (
        <Stack marginBottom={1}>
          <FormLabel id={`formlabel-${name}`}
            sx={{ color: ` ${theme.palette.text.primary} !important` }}
          >
            {label}
          </FormLabel>
        </Stack>
      )}
      <StyledRating
        {...field}
        {...props}
        name={field.name}
        IconContainerComponent={IconContainer}
        getLabelText={(value: number) => customIcons[value].label}
        highlightSelectedOnly
        sx={{
          justifyContent: {
            xs: "space-between",
            md: "flex-start",
          },
        }}
      />
    </FormControl>
  );
};
