import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";

import * as ContextApi from "./context";
import { queryClient } from "./query-client";
import { Router } from "./router";
import GlobalStyle from "./styles/global";
import { themeConfig } from "./styles/mui-style";
import { ThemeProvider } from "@mui/material/styles";
import { css } from "@emotion/css";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@mui/material";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const toastClassNameFont = css({
  fontFamily: "Sora, Arial, Times New Roman, Serif",
  fontSize: 14,
});

const AppRouter = () => {
  const theme = useTheme();
  const [backgroundColor, setBackgroundColor] = useState<string>(theme.palette.background.default)

  useEffect(() => {
    setBackgroundColor(theme.palette.background.default)
  }, [theme.palette.background.default])

  return (
    <>
      <GlobalStyle backgroundColor={backgroundColor} />
      <ToastContainer toastClassName={toastClassNameFont} />
      <Router />
    </>
  )
}

const App = () => {
  const [{ themeMode }] = ContextApi.useGlobalState()
  return (
    <ThemeProvider theme={themeConfig({ mode: themeMode })}>
      <AppRouter />
    </ThemeProvider>
  )
}

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ContextApi.GlobalStateProvider>
        <App />
      </ContextApi.GlobalStateProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
