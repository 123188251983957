import React from "react";
import { Outlet } from "react-router-dom";

import * as S from "./style";
import { useGlobalState } from "../../context";
import { Button } from "@mui/material";

export const NoAccess = () => {
  const [, dispatch] = useGlobalState();

  return (
    <S.Wrapper>
      <h1>No access</h1>
      <Button
        variant="outlined"
        onClick={() => {
          dispatch({
            type: "SET_LOGOUT",
          });
        }}
      >
        Deslogar
      </Button>
      <Outlet />
    </S.Wrapper>
  );
};
