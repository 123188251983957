import React from "react";

import { styled } from "@mui/material/styles";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { TbCircleCheck } from "react-icons/tb";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps, Typography } from "@mui/material";
import { grey, green } from "@mui/material/colors";
import { useTheme } from "@mui/material";

import Stepper from "@mui/material/Stepper";
import { IconType } from "react-icons";

export interface StepperTitleList {
  title: string;
  icon: IconType;
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 11,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: green[500],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: green[500],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : grey[200],
    borderTopWidth: 3,
    borderRadius: 10,
  },
}));

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : grey[200],
    display: "flex",
    height: 22,
    // alignItems: "center",
    ...(ownerState.active && {
      color: green[500],
    }),
    "& .QontoStepIcon-completedIcon": {
      color: green[500],
      // zIndex: 1,
    },
    "& .QontoStepIcon-circle": {
      // width: 8,
      // height: 8,
      // borderRadius: "50%",
      // backgroundColor: "currentColor",
    },
  })
);

interface QontoStepIconProps {
  stepIconProps: StepIconProps;
  icon: IconType;
}
function QontoStepIcon({ stepIconProps, icon: Icon }: QontoStepIconProps) {
  const theme = useTheme();
  const { active, completed, className } = stepIconProps;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed && <TbCircleCheck size={24} color={green[500]} />}
      {!completed && !active && <Icon color={grey[300]} size={24} />}
      {!completed && active && (
        <Icon color={theme.palette.primary.main} size={24} />
      )}
    </QontoStepIconRoot>
  );
}

interface StepperTitleProps {
  stepperList: StepperTitleList[];
  activeStep: number;
  alternativeLabel?: boolean;
}
export const StepperTitle = ({
  stepperList,
  activeStep,
  alternativeLabel,
}: StepperTitleProps) => {
  return (
    <Stepper
      alternativeLabel={alternativeLabel}
      activeStep={activeStep}
      connector={<QontoConnector />}
    >
      {stepperList.map((item, index) => (
        <Step
          key={String(index)}
          disabled={index === 1}
        // completed={index === steps.length}
        >
          <StepLabel
            optional
            StepIconComponent={(props) => (
              <QontoStepIcon stepIconProps={props} icon={item.icon} />
            )}
          >
            <Typography
              sx={{
                typography: {
                  md: "subtitle2",
                },
                fontSize: {
                  xs: 10,
                },
              }}
            >
              {item.title}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
