export const BASE_URL_DEV = "http://localhost:5000";
export const BASE_URL_TEST = "https://franchise-api-hml-9n4b.onrender.com";
export const BASE_URL_PROD = "https://franchise-api-a29q.onrender.com";

export const TERMS =
  "https://franchise-api-a29q.onrender.com/assets/terms/rari_conduta_e_etica_empresarial_.PDF";

export const ENDPOINTS = {
  ME: "/me",
  USER_QUIZ: "/user-quiz",
  LOGIN: "/login",
  SIGNUP: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  ENABLE_ACCOUNT: "/enable-account",
  UPLOAD: "/upload",
  CONTACT: "/contact",
};
