export enum EnumUserType {
  ADMIN = "ADMIN",
  FRANCHISOR = "FRANCHISOR",
  ASSOCIATE = "ASSOCIATE",
  CANDIDATE = "CANDIDATE",
  INTERESTED = "INTERESTED",
}

export enum EnumUserTypeBr {
  ADMIN = "Administrador",
  FRANCHISOR = "Franqueador",
  ASSOCIATE = "Associado",
  CANDIDATE = "Candidato",
  INTERESTED = "Interessado",
}
