import { RoutesNames } from "constants/routes";
import { TbClipboardData, TbDatabaseImport } from "react-icons/tb";

export const HEADER_MENU_NAV: Record<UserTypeKey, HeaderMenuNav[]> = {
  ADMIN: [],
  FRANCHISOR: [
    // {
    //   title: "Início",
    //   isHidden: false,
    //   path: RoutesNames.FRANCHISOR.HOME,
    //   icon: TbHome,
    // },
    {
      title: "Dados",
      isHidden: false,
      path: RoutesNames.FRANCHISOR.DATA,
      icon: TbDatabaseImport,
    },
    {
      title: "Relatório",
      isHidden: false,
      path: RoutesNames.FRANCHISOR.REPORT,
      icon: TbClipboardData,
    },
  ],
  ASSOCIATE: [],
  CANDIDATE: [],
  INTERESTED: [],
};
