import { RoutesNames } from "constants/routes";
import { TbUserCircle } from "react-icons/tb";

export const HEADER_MENU_USER: Record<UserTypeKey, HeaderMenuUser[]> = {
  ADMIN: [
    {
      title: "Minha conta",
      isHidden: false,
      path: RoutesNames.ADMIN.PROFILE,
      icon: TbUserCircle,
    },
  ],
  ASSOCIATE: [
    {
      title: "Minha conta",
      isHidden: false,
      path: RoutesNames.ASSOCIATE.PROFILE,
      icon: TbUserCircle,
    },
  ],
  FRANCHISOR: [
    {
      title: "Minha conta",
      isHidden: false,
      path: RoutesNames.FRANCHISOR.PROFILE,
      icon: TbUserCircle,
    },
  ],
  CANDIDATE: [
    {
      title: "Minha conta",
      isHidden: false,
      path: RoutesNames.CANDIDATE.PROFILE,
      icon: TbUserCircle,
    },
  ],
  INTERESTED: [
    {
      title: "Minha conta",
      isHidden: false,
      path: RoutesNames.INTERESTED.PROFILE,
      icon: TbUserCircle,
    },
  ],
};
