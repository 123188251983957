import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import { FormikSelectField, FormikTextField } from "components";

import { useGlobalState } from "context";
import { ApiService } from "services";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { LoadingButton } from "@mui/lab";
import { Form, FormikProvider, useFormik } from "formik";
import { FormikSelectFieldMenu } from "components/formik-select-field";
import { validationSchemaSignup } from "./validationSchema";
import { Grid, useTheme } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

interface MutationAuthProps {
  fullname: string;
  email: string;
  password: string;
  confirm_password: string;
  type_id: number | string;
}
const INITIAL_VALUES_SIGNUP: MutationAuthProps = {
  fullname: "",
  email: "",
  password: "",
  confirm_password: "",
  type_id: "",
};
const MENU_LIST: FormikSelectFieldMenu[] = [
  {
    valeu: 1,
    title: "Fraqueador",
  },
  {
    valeu: 2,
    title: "Já comprei uma franquia",
  },
  {
    valeu: 3,
    title: "Quero comprar minha primeira franquia",
  },
];

interface AuthSignupProps {
  onLogin: () => void
  isSignupHome: boolean
}
export const AuthSignup = ({ onLogin, isSignupHome }: AuthSignupProps) => {
  const theme = useTheme();
  const [, dispatch] = useGlobalState();
  const recaptchaRef = React.createRef<any>();
  const [loadingSignup, setLoadingSignup] = useState<boolean>(false);

  const isValidGoogleRecaptcha = async () => {
    return true;

    // if (process.env.REACT_APP_NODE_ENV === "development") return true;
    // const responseToken = await recaptchaRef.current.executeAsync();
    // const responseGoogleReCaptcha = await ApiService.Auth.googleReCaptcha({
    //   responseToken,
    // });

    // if (
    //   !responseGoogleReCaptcha.success ||
    //   responseGoogleReCaptcha.score <= 0.4
    // ) {
    //   return false;
    // }
    // return true;
  };

  const formikSignup = useFormik({
    initialValues: INITIAL_VALUES_SIGNUP,
    validationSchema: validationSchemaSignup,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setLoadingSignup(true);
      const isValidRecaptcha = await isValidGoogleRecaptcha();

      if (!isValidRecaptcha) {
        setLoadingSignup(false);
        toast.error("Cadastro bloqueado! Por favor, entre em contato.", {
          autoClose: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }

      mutationSignup.mutate(values);
    },
  });

  const mutationSignup = useMutation<User, AxiosError, MutationAuthProps>(
    "signup",
    (data) => ApiService.Auth.signup(data),
    {
      onSuccess: (resp: User) => {
        setLoadingSignup(false);
        formikSignup.resetForm();
        dispatch({
          type: "SET_USER",
          data: resp,
        });
        toast.success("Cadastrado com sucesso!", {
          autoClose: 10000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      },
      onError: (resp) => {
        setLoadingSignup(false);
        const data = resp.response?.data as ApiErrorResponse;
        toast.error(data.message, {
          autoClose: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      },
    }
  );

  return (
    <Stack id='signup' sx={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    }}>

      {isSignupHome && (
        <>
          <Typography variant='h6' color='text.primary' textAlign='center' fontWeight={200}>
            CADASTRE-SE
          </Typography>
          <Stack mb={2} />
        </>
      )}

      <Stack
        id='signup'
        sx={{
          width: { xs: '100%', md: 600 },
          backgroundColor: theme.palette.background.paper,
          paddingY: 5,
          paddingX: { xs: 2, sm: 5 },
          borderRadius: 2,
        }}>

        {!isSignupHome && (
          <>
            <Typography variant="h6" textAlign="center" color='text.primary'>
              Ainda não tenho uma conta
            </Typography>
            <Stack mb={2} />
          </>
        )}

        <FormikProvider value={formikSignup}>
          <Form>
            <Stack
              spacing={2}
              justifyContent="center"
            >
              <FormikTextField name="fullname" label="Nome completo" />
              <FormikTextField name="email" label="Email" />
              <FormikTextField
                name="password"
                label="Senha"
                type="password"
              />
              <FormikTextField
                name="confirm_password"
                label="Confirmar senha"
                type="password"
              />
              <FormikSelectField
                name="type_id"
                label="Selecione seu perfil"
                menuList={MENU_LIST}
              />
            </Stack>

            <Grid container pt={4}
              spacing={2}
            >
              <Grid item
                xs={12}
                sm={6}
                display={isSignupHome ? 'none' : 'block'}>
                <LoadingButton
                  fullWidth
                  loadingPosition="center"
                  variant="outlined"
                  size="large"
                  disableElevation
                  onClick={onLogin}
                >
                  Já tenho conta
                </LoadingButton>
              </Grid>
              <Grid item
                xs={12}
                sm={isSignupHome ? 12 : 6}
              >
                <LoadingButton
                  fullWidth
                  type="submit"
                  loading={loadingSignup}
                  loadingPosition="center"
                  variant="contained"
                  size="large"
                >
                  Criar conta
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Stack>
      {isSignupHome && (
        <Stack sx={{ width: { xs: '100%', md: 600 } }}>
          <Stack mb={5} />
          <LoadingButton
            fullWidth
            loadingPosition="center"
            variant="outlined"
            size="large"
            onClick={onLogin}
          >
            Já tenho uma conta
          </LoadingButton>
        </Stack>
      )}

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY_SITE || ""}
      />
    </Stack>
  );
};

