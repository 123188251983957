export const setNameToFile = (file: File, newFileName?: string) => {
  let temp = ''
  if (newFileName) {
    temp = `${newFileName}.${file.name.split('.').pop()}`
  } else {
    temp = file.name
  }

  return temp
}
