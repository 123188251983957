import React from "react";

import { styled } from "@mui/material/styles";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { TbCircleCheckFilled, TbCircleDot, TbVocabulary } from "react-icons/tb";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Stack, StepIconProps } from "@mui/material";
import { grey, green, blue, teal } from "@mui/material/colors";
import Stepper from "@mui/material/Stepper";

import CircularProgress from "@mui/material/CircularProgress";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    // top: 9,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: green[500],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: green[500],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : grey[200],
    minHeight: 40,
    borderLeftWidth: 2,
  },
}));

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : grey[200],
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: green[500],
    }),
    "& .QontoStepIcon-completedIcon": {
      color: green[500],
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed && (
        <TbCircleCheckFilled
          size={24}
          className="QontoStepIcon-completedIcon"
          style={{ marginLeft: 1 }}
        />
      )}
      {!completed && !active && (
        <TbCircleDot size={24} style={{ marginLeft: 1 }} />
      )}
      {!completed && active && (
        <CircularProgress color="warning" size={20} sx={{ marginLeft: 0.4 }} />
      )}
    </QontoStepIconRoot>
  );
}

interface StepperProps {
  steps: String[];
  activeStep: number;
}
export const StepperVertical = ({ steps, activeStep }: StepperProps) => {
  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      connector={<QontoConnector />}
    >
      {steps.map((step, index) => (
        <Step key={String(index)}>
          <StepLabel StepIconComponent={QontoStepIcon}>
            <Stack flexDirection="row" alignItems="center">
              {step}
              {/* <TbVocabulary
                color={blue[500]}
                size={14}
                style={{ marginLeft: 8 }}
              /> */}
            </Stack>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
