import { ENDPOINTS } from "../../../constants/endpoint";

import { UserQuiz, api } from "..";
import { EnumUserType } from "constants/user-type";

export const getMe = async (): Promise<User> => {
  const me = await api.get(ENDPOINTS.ME).then((response) => response.data);

  const user_type = Object.values(EnumUserType)[me.type_id];

  if (user_type === "ASSOCIATE" || user_type === "CANDIDATE") {
    const userQuiz = await UserQuiz.findUserQuiz({
      quiz_id: 1,
    });
    if (Object.keys(userQuiz).length > 0) {
      me["quiz_is_finished"] = userQuiz.is_finished;
    } else {
      me["quiz_is_finished"] = false;
    }
  }

  return {
    ...me,
    user_type,
  };
};

interface UpdateMeProps {
  firstname: string;
  lastname: string;
  fullname: string;
}
export const updateMe = async (data: UpdateMeProps) => {
  return api.put(ENDPOINTS.ME, data).then((response) => response.data);
};

interface UpdateMePasswordProps {
  password: string;
  new_password: string;
}
export const updateMePassword = async (data: UpdateMePasswordProps) => {
  return api
    .put(`${ENDPOINTS.ME}/password`, data)
    .then((response) => response.data);
};

interface DeleteMeProps {
  reason: string;
  description?: string;
}
export const deleteMe = async (data: DeleteMeProps) => {
  return api
    .post(`${ENDPOINTS.ME}/delete`, data)
    .then((response) => response.data);
};
