import React from "react";
import { useField } from "formik";

import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";

import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { SxProps, Theme, Typography } from "@mui/material";
type FormikCheckboxProps = {
  name: string;
  label?: string;
  sx?: SxProps<Theme>;
} & CheckboxProps;
export const FormikCheckbox = ({
  name,
  label,
  sx,
  ...props
}: FormikCheckboxProps) => {
  const [
    field,
    meta,
    // helpers
  ] = useField(name);

  return (
    <>
      <FormControlLabel
        key={name}
        control={
          <Checkbox {...field} {...props} name={name} checked={field.value} />
        }
        label={
          <Typography variant="body1" color="text.primary">
            {label}
          </Typography>
        }
        sx={sx}
      />

      {meta.touched && Boolean(meta.error) && (
        <FormHelperText sx={{ color: "#fe494cff !important" }}>
          {meta.error}
        </FormHelperText>
      )}
    </>
  );
};

{
  /* <FormikCheckbox
  name="multi"
  label="Multi test"
  sx={{ padding: 1, border: "1px solid #eee", borderRadius: 2 }}
/> */
}
