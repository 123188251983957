import { ENDPOINTS } from "../../../constants/endpoint";

import { UserQuiz, api } from "..";
import { EnumUserType } from "constants/user-type";
import { ID_LOCAL_STORAGE_ACCESS_TOKEN } from "constants/cookie-local-storage";
interface AuthProps {
  fullname: string;
  email: string;
  password: string;
  confirm_password: string;
  type_id: number | string;
}

export const login = async (
  data: Pick<AuthProps, "email" | "password">
): Promise<User> => {
  const user = await api
    .post(ENDPOINTS.LOGIN, data)
    .then((response) => response.data);

  localStorage.setItem(ID_LOCAL_STORAGE_ACCESS_TOKEN, user.access_token);
  const user_type = Object.values(EnumUserType)[user.type_id];

  if (user_type === "ASSOCIATE" || user_type === "CANDIDATE") {
    const userQuiz = await UserQuiz.findUserQuiz({
      quiz_id: 1,
    });

    if (Object.keys(userQuiz).length > 0) {
      user["quiz_is_finished"] = userQuiz.is_finished;
    } else {
      user["quiz_is_finished"] = false;
    }
  }

  return {
    ...user,
    user_type,
  };
};

export const signup = async (data: AuthProps): Promise<User> => {
  const dt = {
    fullname: data.fullname,
    email: data.email,
    password: data.password,
    type_id: data.type_id,
  };

  const user = await api
    .post(ENDPOINTS.SIGNUP, dt)
    .then((response) => response.data);

  localStorage.setItem(ID_LOCAL_STORAGE_ACCESS_TOKEN, user.access_token);
  const user_type = Object.values(EnumUserType)[user.type_id];

  if (user_type === "ASSOCIATE" || user_type === "CANDIDATE") {
    const userQuiz = await UserQuiz.findUserQuiz({
      quiz_id: 1,
    });

    if (Object.keys(userQuiz).length > 0) {
      user["quiz_is_finished"] = userQuiz.is_finished;
    } else {
      user["quiz_is_finished"] = false;
    }
  }

  return {
    ...user,
    user_type,
  };
};

export const forgotPassword = async (data: Pick<AuthProps, "email">) => {
  return api
    .post(ENDPOINTS.FORGOT_PASSWORD, data)
    .then((response) => response.data);
};

interface ResetPasswordProps {
  password: string;
  token: string;
}
export const resetPassword = async (data: ResetPasswordProps) => {
  return api
    .post(ENDPOINTS.RESET_PASSWORD, data)
    .then((response) => response.data);
};

interface EnableAccountProps {
  token: string;
}
export const enableAccount = async ({ token }: EnableAccountProps) => {
  return api
    .get(`${ENDPOINTS.ENABLE_ACCOUNT}/${token}`)
    .then((response) => response.data);
};

interface GoogleReCaptchaProps {
  responseToken: string;
}
export const googleReCaptcha = async ({
  responseToken,
}: GoogleReCaptchaProps): Promise<GoogleReCaptcha> => {
  return api
    .get(`google-recaptch/${responseToken}`)
    .then((response) => response.data);
};
