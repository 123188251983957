import * as React from "react";
import MobileStepper from "@mui/material/MobileStepper";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface StepperMobileProps {
  stepperLength: number;
  activeStep: number;
}

export const StepperMobile = ({
  stepperLength,
  activeStep,
}: StepperMobileProps) => {
  const theme = useTheme();
  const isEqualUpSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <MobileStepper
      variant={isEqualUpSm ? "dots" : "progress"}
      steps={stepperLength}
      position="static"
      activeStep={activeStep}
      nextButton={null}
      backButton={null}

      sx={
        isEqualUpSm
          ? {
            width: "100%",
            justifyContent: "center",
            "& > div": {
              width: "100%",
              justifyContent: "space-between",
            },
            '.MuiMobileStepper-dot': { backgroundColor: theme.palette.borderMediumColor.main },
            '.MuiMobileStepper-dotActive': { backgroundColor: theme.palette.primary.main },
          }
          : {
            paddingX: 4,
            width: "100%",
            justifyContent: "center",
            "& > span": {
              width: "100%",
              justifyContent: "space-between",
            },
          }
      }
    />
  );
};
