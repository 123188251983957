import React, { useMemo, useState } from "react";

import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";

import { FormHelperText, Grid } from "@mui/material";
import { grey, red } from "@mui/material/colors";
import {
  FormikCheckbox,
  FormikRadioGroup,
  FormikRadioImageGroup,
  FormikRatingFace,
  FormikTextField,
} from "../../../../components";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";

import FormLabel from "@mui/material/FormLabel";

import FormGroup from "@mui/material/FormGroup";
import { useMutation } from "react-query";
import { ApiService } from "../../../../services";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { QuestionTitle } from "../question-title";
import { AxiosError } from "axios";
import { TbChevronLeft } from "react-icons/tb";
import { useTheme } from "@mui/material";
import { useGlobalState } from "context";

interface MutationFinishedQuiz {
  answers: string;
}

// const StyleButtomFixed = {
//   zIndex: {
//     xs: 1,
//     md: 1,
//   },
//   position: {
//     xs: "fixed",
//     md: "relative",
//   },
//   bottom: {
//     xs: 80,
//     md: 0,
//   },
//   left: {
//     xs: 0,
//   },
//   right: {
//     xs: 0,
//   },
//   height: {
//     xs: 70,
//     md: "auto",
//   },
//   backgroundColor: {
//     xs: "white",
//     md: "transparent",
//   },
//   paddingTop: {
//     xs: 0,
//     md: 10,
//   },
//   paddingBottom: {
//     xs: 0,
//     md: 5,
//   },
//   borderTop: {
//     xs: `2px solid ${grey[300]}`,
//     md: "none",
//   },
//   paddingY: {
//     xs: 2,
//     md: 0,
//   },
//   paddingX: {
//     xs: 4,
//     md: 0,
//   },
//   borderTopLeftRadius: {
//     xs: 20,
//     md: 0,
//   },
//   borderTopRightRadius: {
//     xs: 20,
//     md: 0,
//   },
//   flexDirection: {
//     xs: "row",
//     md: "column",
//   },
//   gap: {
//     xs: 2,
//     md: 0,
//   },
// };
interface QuestionFieldProps {
  question: QuizQuestion;
  hasError?: boolean;
}
const FieldType = ({ question, hasError }: QuestionFieldProps) => {
  const theme = useTheme();
  const { id, options, type, is_required, has_answer_writing_aditional } =
    question;

  const renderAnswerWritingAditional = () => {
    return (
      <Stack mt={2}>
        <FormikTextField
          name={`question_${id}.answer_writing_aditional`}
          multiline
          rows={2}
          label="Deseja acrescentar alguma observação para a sua escolha? Escreva aqui..."
        />
      </Stack>
    );
  };

  if (type === "WRITING") {
    return (
      <Stack>
        <Stack marginBottom={1}>
          <FormLabel
            id="demo-radio-buttons-group-label"
            sx={{ color: ` ${theme.palette.text.primary} !important` }}
          >
            Digíte sua resposta {is_required && "*"}
          </FormLabel>
        </Stack>

        <FormikTextField
          name={`question_${id}.answer`}
          multiline
          rows={6}
          label="Resposta..."
          error={hasError}
        />
      </Stack>
    );
  }

  if (type === "SINGLE") {
    const response = (options || []).map((item) => ({
      value: item.id.toString(),
      title: item.title,
    }));

    return (
      <>
        <FormikRadioGroup
          name={`question_${id}.answer`}
          label={`Escolha uma opção ${is_required && "*"}`}
          radioList={response}
          sxRadio={{
            padding: 1,
            // border: `1px solid ${grey[100]}`,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
            minHeight: 66,
          }}
        />

        {has_answer_writing_aditional && renderAnswerWritingAditional()}
      </>
    );
  }

  if (type === "MULTIPLE") {
    return (
      <>
        <FormGroup>
          <FormLabel
            id="demo-radio-buttons-group-label"
            sx={{ color: ` ${theme.palette.text.primary} !important` }}
          >
            Escolha várias opções {is_required && "*"}
          </FormLabel>

          <Stack spacing={2} marginTop={1}>
            {options?.map((option) => (
              <FormikCheckbox
                key={option.id}
                name={`question_${id}.answer_options.option_${option.id}`}
                label={option.title}
                // value={option.id}
                sx={{
                  padding: 1,
                  // border: `1px solid ${grey[100]}`,
                  borderRadius: 2,
                  backgroundColor: theme.palette.background.paper,
                  minHeight: 66,
                }}
              />
            ))}
          </Stack>
        </FormGroup>

        {has_answer_writing_aditional && renderAnswerWritingAditional()}
      </>
    );
  }

  if (type === "SYMBOL") {
    return (
      <>
        <FormikRatingFace
          name={`question_${id}.answer`}
          label={`Selecione uma opção ${is_required && "*"}`}
        />

        {has_answer_writing_aditional && renderAnswerWritingAditional()}
      </>
    );
  }

  if (type === "IMAGE") {
    const response = (options || []).map((item) => ({
      value: item.id.toString(),
      title: "", // item.title,
      imageUrl: item.image_url || "",
    }));

    return (
      <>
        <FormikRadioImageGroup
          name={`question_${id}.answer`}
          label={`Escolha uma opção das imagens abaixo que melhor corresponda à sua resposta. ${
            is_required && "*"
          }`}
          // value={option.id}
          radioImageList={response}
        />
        {has_answer_writing_aditional && renderAnswerWritingAditional()}
      </>
    );
  }

  return null;
};

interface QuestionPros {
  question: QuizQuestion;
  questionIndex: number;
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  answers: QuizInitialStateAnswerType;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  submitForm: () => void;
}
const Question = ({
  question,
  questionIndex,
  isFirstQuestion,
  isLastQuestion,
  answers,
  setActiveStep,
  submitForm,
}: QuestionPros) => {
  const theme = useTheme();
  const [{ themeMode }] = useGlobalState();
  const [showError, setShowError] = useState<string>("");
  const { id, title, is_title_with_name, is_required, type } = question;
  const questionAnswer = answers[`question_${question.id}`];

  const hasAnswer = useMemo(() => {
    if (!is_required) return true;

    if (type === "MULTIPLE") {
      return Object.keys(questionAnswer.answer_options).some((item: string) => {
        const answerOptions = questionAnswer.answer_options as Record<
          string,
          string
        >;
        return !!answerOptions[item];
      });
    }

    return !!questionAnswer.answer;
  }, [questionAnswer, is_required, type]);

  const showErrorInput = useMemo(
    () => !hasAnswer && !!showError,
    [hasAnswer, showError]
  );

  const mutationSaveAnswer = useMutation(
    "updateQuizAssociate",
    () =>
      ApiService.UserQuiz.updateQuizAssociate({
        quiz_id: 1,
        is_finish: false,
        quizData: {
          answers: JSON.stringify(answers),
          question_id_last_answered: id,
        },
      }),
    {
      onSuccess: () => {
        isLastQuestion ? submitForm() : setActiveStep((prev) => prev + 1);
      },
      onError: (resp) => {
        toast.error("Error ao salvar a resposta! Por favor, tente novamente.", {
          autoClose: 10000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      },
    }
  );

  const msgErrorValidation = (): string => {
    if (type === "MULTIPLE") return "Selecione ao menos uma opção";
    if (type === "SINGLE") return "É obrigatório escolher uma opção";
    if (type === "WRITING") return "Campo obrigatório";
    if (type === "SYMBOL") return "Selecione ao menos uma opção";
    if (type === "IMAGE") return "Selecione ao menos uma imagem";
    return "";
  };

  const handleNextQuestion = () => {
    if (hasAnswer) {
      mutationSaveAnswer.mutate();
      window.scrollTo(0, 0);
      return;
    }

    setShowError(msgErrorValidation());
  };

  return (
    <Stack marginTop={4}>
      <QuestionTitle
        title={title}
        isTitleWithName={is_title_with_name}
        // subtitle={`Questão ${questionIndex + 1}`}
        isDisableBack={isFirstQuestion}
        onBackClicked={() => setActiveStep((prev) => prev - 1)}
      />

      <Stack
        sx={{
          minHeight: "250px",
          marginTop: {
            xs: 6,
          },
          marginBottom: {
            xs: 4,
            md: 6,
          },
        }}
      >
        <FieldType question={question} hasError={showErrorInput} />
        <Stack ml={1}>
          {showErrorInput && (
            <FormHelperText sx={{ color: red[700] }}>
              {showError}
            </FormHelperText>
          )}
        </Stack>
      </Stack>

      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <Stack
            sx={{
              zIndex: {
                xs: 1,
                md: 1,
              },
              position: {
                xs: "fixed",
                md: "relative",
              },
              bottom: {
                xs: 80,
                md: 0,
              },
              left: {
                xs: 0,
              },
              right: {
                xs: 0,
              },
              height: {
                xs: 70,
                md: "auto",
              },
              backgroundColor: {
                xs: theme.palette.background.paper,
                md: "transparent",
              },
              paddingTop: {
                xs: 0,
                md: 10,
              },
              paddingBottom: {
                xs: 0,
                md: 5,
              },
              borderTop: {
                // xs: `2px solid ${theme.palette.background}`,
                xs: `2px solid ${
                  themeMode === "light" ? grey[300] : grey[800]
                }`,
                md: "none",
              },
              paddingY: {
                xs: 2,
                md: 0,
              },
              paddingX: {
                xs: 4,
                md: 0,
              },
              borderTopLeftRadius: {
                xs: 20,
                md: 0,
              },
              borderTopRightRadius: {
                xs: 20,
                md: 0,
              },
              flexDirection: {
                xs: "row",
                md: "column",
              },
              gap: {
                xs: 2,
                md: 0,
              },
            }}
          >
            <LoadingButton
              onClick={() => setActiveStep((prev) => prev - 1)}
              fullWidth
              size="large"
              disabled={isFirstQuestion}
              startIcon={<TbChevronLeft size={24} />}
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                },
              }}
            >
              Voltar
            </LoadingButton>
            <LoadingButton
              onClick={handleNextQuestion}
              variant="contained"
              fullWidth
              color={isLastQuestion ? "success" : "primary"}
              size="large"
              loadingPosition="center"
              loading={mutationSaveAnswer.isLoading}
            >
              {isLastQuestion ? "Finalizar" : "Continuar"}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

interface FormProps {
  quiz: Quiz;
  activeStep: number;
  question_id_last_answered: number | undefined;
  initialValues: QuizInitialStateAnswerType;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setFinishedQuiz: React.Dispatch<React.SetStateAction<boolean>>;
}
export function Form({
  quiz,
  activeStep,
  question_id_last_answered,
  initialValues,
  setActiveStep,
  setFinishedQuiz,
}: FormProps) {
  const formikQuiz = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const quizQuestionToSave: QuizQuestionToSave[] = [];

      quiz.questions.forEach((question) => {
        const { type, options } = question;
        const questionId = `question_${question.id}`;

        if (type === "SINGLE") {
          const questionAnswer = values[questionId].answer;
          const questionAnswerWritingAditional =
            values[questionId].answer_writing_aditional;

          const opt: QuestionOptionToSave[] = [];
          options?.forEach((item) => {
            opt.push({
              ...item,
              is_selected: String(item.id) === questionAnswer,
            });
          });
          const questionWithOutOptions = { ...question };
          delete questionWithOutOptions.options;
          quizQuestionToSave.push({
            ...questionWithOutOptions,
            answer_options: opt,
            answer_writing_aditional: (questionAnswerWritingAditional ||
              "") as string,
          });
        }
        if (type === "MULTIPLE") {
          const questionAnswerOptions = values[questionId]
            .answer_options as Record<string, boolean>;
          const questionAnswerWritingAditional =
            values[questionId].answer_writing_aditional;

          const opt: QuestionOptionToSave[] = [];
          options?.forEach((item) => {
            const optionIdAnswer = `option_${item.id}`;
            opt.push({
              ...item,
              is_selected: !!questionAnswerOptions[optionIdAnswer],
            });
          });
          const questionWithOutOptions = { ...question };
          delete questionWithOutOptions.options;
          quizQuestionToSave.push({
            ...questionWithOutOptions,
            answer_options: opt,
            answer_writing_aditional: (questionAnswerWritingAditional ||
              "") as string,
          });
        }
        if (type === "WRITING" || type === "SYMBOL" || type === "IMAGE") {
          const questionAnswer = values[questionId].answer as string;
          const questionAnswerWritingAditional =
            values[questionId].answer_writing_aditional;

          const questionWithOutOptions = { ...question };
          delete questionWithOutOptions.options;

          if (type === "WRITING") {
            quizQuestionToSave.push({
              ...questionWithOutOptions,
              answer_options: undefined,
              answer: questionAnswer,
            });
          } else {
            quizQuestionToSave.push({
              ...questionWithOutOptions,
              answer_options: undefined,
              answer: questionAnswer,
              answer_writing_aditional: (questionAnswerWritingAditional ||
                "") as string,
            });
          }
        }
      });

      const quizToSave: QuizToSave = {
        ...quiz,
        questions: quizQuestionToSave,
      };

      mutationFinishedQuiz.mutate({ answers: JSON.stringify(quizToSave) });
    },
  });

  const mutationFinishedQuiz = useMutation<
    UserQuiz,
    AxiosError,
    MutationFinishedQuiz
  >(
    "updateQuizAssociate",
    (props) =>
      ApiService.UserQuiz.updateQuizAssociate({
        quiz_id: 1,
        is_finish: true,
        quizData: {
          answers: props.answers,
          is_finished: true,
        },
      }),
    {
      onSuccess: () => {
        setActiveStep((prev) => prev + 1);
        setFinishedQuiz(true);
      },
      onError: (resp) => {
        toast.error(
          "Error ao finalizar o questionário! Por favor, tente novamente.",
          {
            autoClose: 10000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      },
    }
  );

  const [redirectedToStepIndex, setRedirectedToStepIndex] =
    useState<boolean>(false);

  return (
    <FormikProvider value={formikQuiz}>
      <FormikForm>
        {quiz.questions.map((question, index) => {
          if (
            !redirectedToStepIndex &&
            question_id_last_answered &&
            question.id === question_id_last_answered
          ) {
            setRedirectedToStepIndex(true);
            setActiveStep(index);
          }

          return (
            <>
              {index === activeStep && (
                <>
                  <Question
                    key={question.id}
                    question={question}
                    questionIndex={index}
                    isFirstQuestion={index === 0}
                    isLastQuestion={index + 1 === quiz.questions.length}
                    submitForm={formikQuiz.submitForm}
                    answers={formikQuiz.values}
                    setActiveStep={setActiveStep}
                  />
                </>
              )}
            </>
          );
        })}
      </FormikForm>
    </FormikProvider>
  );
}
