// https://www.npmjs.com/package/react-google-recaptcha
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { AuthLogin, AuthSignup, Footer } from "../../components";
import { useGlobalState } from "../../context";
import { useMediaQuery, useTheme } from "@mui/material";
import { IMAGES } from '../../constants/images'

export const Auth = () => {
  const theme = useTheme();
  const [{ themeMode }] = useGlobalState();
  const [showLoginForm, setShowLoginForm] = useState<boolean>(true);
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Stack
      sx={{
        width: '100%',
        paddingY: 5,
        minHeight: '100vh',
        backgroundImage: `url(${themeMode === 'light' ? IMAGES.BG1_LIGHT : IMAGES.BG1_DARK})`,
        backgroundSize: `cover`,
        backgroundPosition: `0px 0px`,
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Stack>
        <Stack
          paddingBottom={6} display='flex' alignItems='center'
          sx={{ padding: { xs: 1, md: 0 } }}
        >
          {themeMode === 'light' ?
            <img
              alt="logo"
              src={IMAGES.LOGO_LIGHT_IMG}
              style={{ width: isXs ? 290 : 360, height: isXs ? 74 : 94 }}
            />
            : <img
              alt="logo"
              src={IMAGES.LOGO_DARK_IMG}
              style={{ width: isXs ? 290 : 360, height: isXs ? 74 : 94 }}
            />}

          <Box mb={5} />

          {/* <Stack width='100%'> */}
          {showLoginForm ? <AuthLogin onSignup={() => setShowLoginForm(false)} /> : <AuthSignup isSignupHome={false} onLogin={() => setShowLoginForm(true)} />}
          {/* </Stack> */}
        </Stack>
        <Box mt={10} />
        <Footer />
      </Stack>


    </Stack>
  );
};

