import React from 'react'

interface SvgProps {
  size: number
}
export const HowWorksDarkSvg = ({ size }: SvgProps) => (
  <svg width={size} height={size} viewBox="0 0 185 187" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.398682" y="65.9912" width="132.196" height="138.934" rx="46.2994" transform="rotate(-29.8322 0.398682 65.9912)" fill="#171717" />
    <rect x="4.34844" y="67.062" width="126.409" height="133.147" rx="43.4057" transform="rotate(-29.8322 4.34844 67.062)" stroke="#B5B2C5" stroke-opacity="0.5" stroke-width="5.78742" />
    <rect x="7.80505" y="129.263" width="126.409" height="133.147" rx="43.4057" transform="rotate(-69.5035 7.80505 129.263)" stroke="#D5D3DD" stroke-opacity="0.2" stroke-width="5.78742" />
  </svg>


)
