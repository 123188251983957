import ACCOUNT from '../../assets/images/account.png'
import BG2_LIGHT from '../../assets/images/bg2-light.png'
import BG2_DARK from '../../assets/images/bg2-dark.png'
import BG1_DARK from '../../assets/images/bg1-dark.png'
import BG3_DARK from '../../assets/images/bg3-dark.png'
import BG1_LIGHT from '../../assets/images/bg1-light.png'
import BG3_LIGHT from '../../assets/images/bg3-light.png'
import BG4_LIGHT from '../../assets/images/bg4-light.png'
import BG4_DARK from '../../assets/images/bg4-dark.png'
import LOGO_DARK_IMG from '../../assets/images/logo-dark.png'
import LOGO_LIGHT_IMG from '../../assets/images/logo-light.png'
import MODELO_DARK_IMG from '../../assets/images/modelo-dark.png'
import MODELO_LIGHT_IMG from '../../assets/images/modelo-light.png'

export const IMAGES = {
  ACCOUNT,

  BG1_LIGHT,
  BG2_LIGHT,
  BG3_LIGHT,
  BG4_LIGHT,

  BG1_DARK,
  BG2_DARK,
  BG3_DARK,
  BG4_DARK,

  MODELO_DARK_IMG,
  MODELO_LIGHT_IMG,

  LOGO_DARK_IMG,
  LOGO_LIGHT_IMG,
}
