import React, { useEffect, useState } from "react";

import Stack from "@mui/material/Stack";

import { Footer, StepperTitle, StepperMobile } from "../../../components";

import { useGlobalState } from "../../../context";
import { AxiosError } from "axios";
import { ApiService } from "../../../services";
import { StepTermsPrivacy } from "./step-terms-privacy";
import { useQuery } from "react-query";
import { Form } from "./form";
import { StepFinished } from "./step-finished";
import { StepIntro } from "./step-intro";
import { StepperTitleList } from "components/stepper-title";
import { Grid, useTheme } from "@mui/material";
import {
  TbSettingsCog,
  TbAward,
  TbPigMoney,
  TbTargetArrow,
} from "react-icons/tb";

// const StyleFooterMobile = {
//   position: {
//     xs: "fixed",
//   },
//   display: {
//     xs: "flex",
//     md: "none",
//   },
//   bottom: {
//     xs: 0,
//   },
//   left: {
//     xs: 0,
//   },
//   right: {
//     xs: 0,
//   },
//   height: {
//     xs: 80,
//     md: "auto",
//   },
//   width: {
//     xs: "100%",
//   },
//   backgroundColor: {
//     xs: "white",
//     md: "transparent",
//   },
//   paddingTop: {
//     md: 20,
//     xs: 0,
//   },
//   paddingBottom: {
//     md: 5,
//     xs: 0,
//   },
//   zIndex: {
//     xs: 1,
//     md: 1,
//   },
// };

const StyleWrap = {
  paddingBottom: {
    xs: 20,
    md: 0,
  },
};

export const stepsQuestionGroup: StepperTitleList[] = [
  {
    title: "Gestão e processos",
    icon: TbSettingsCog,
  },
  {
    title: "Liderança e inovação",
    icon: TbAward,
  },
  {
    title: "Empreendedor",
    icon: TbTargetArrow,
  },
  {
    title: "Socioeconômico",
    icon: TbPigMoney,
  },
];

export function Quiz() {
  const theme = useTheme();
  const [{ user }] = useGlobalState();
  const [activeStepGroup, setActiveStepGroup] = useState<number>(0);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [finishedQuiz, setFinishedQuiz] = useState<boolean>(false);
  const [showStepIntro, setShowStepIntro] = useState<boolean>(true);
  const [formQuizInitialValues, setFormQuizInitialValues] = useState<
    QuizInitialStateAnswerType | undefined
  >(undefined);

  const {
    data: dataUserQuiz,
    isLoading,
    refetch,
  } = useQuery<UserQuiz | undefined, AxiosError>(
    "findUserQuiz",
    async () =>
      ApiService.UserQuiz.findUserQuiz({
        quiz_id: 1,
      }),
    {
      onSuccess: (resp) => {
        if (!resp?.quiz) return;
        const { quiz, answers } = resp;
        if (quiz.questions.length === 0) return;

        const initialValueTemp: QuizInitialStateAnswerType = {};

        quiz.questions.forEach((question) => {
          const { type, options } = question;

          if (type === "WRITING") {
            let temp: Record<string, string | boolean> = {
              answer: "",
            };
            initialValueTemp[`question_${question.id}`] = temp;
          } else {
            if (type === "MULTIPLE") {
              const optionLocal: Record<string, string | boolean> = {};
              (options || []).forEach((option) => {
                optionLocal[`option_${option.id}`] = false;
              });
              initialValueTemp[`question_${question.id}`] = {
                answer_options: { ...optionLocal },
                answer_writing_aditional: "",
              };
            } else {
              let temp2: Record<string, string | boolean> = {
                answer: "",
                answer_writing_aditional: "",
              };
              initialValueTemp[`question_${question.id}`] = temp2;
            }
          }
        });

        const data = {
          ...initialValueTemp,
          ...(answers ? JSON.parse(answers) : {}),
        } as QuizInitialStateAnswerType;

        setFormQuizInitialValues(data);
      },
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const renderStepper = () => {
    if (!finishedQuiz && user?.accepted_terms_privacy) {
      return (
        <>
          <Stack>
            <StepperTitle
              alternativeLabel
              activeStep={activeStepGroup - 1}
              stepperList={stepsQuestionGroup}
            />

            <Stack mb={1} />
            <StepperMobile
              stepperLength={dataUserQuiz?.quiz?.questions?.length || 0}
              activeStep={activeStep}
            />
          </Stack>
        </>
      );
    }

    return null;
  };

  useEffect(() => {
    if (
      !dataUserQuiz ||
      !dataUserQuiz?.quiz ||
      !dataUserQuiz?.quiz?.questions ||
      activeStep >= dataUserQuiz.quiz.questions.length - 1
    ) {
      return;
    }

    const { question_group_id } = dataUserQuiz.quiz.questions[activeStep];

    if (question_group_id !== activeStepGroup) {
      setActiveStepGroup(question_group_id);
    }
  }, [activeStep, activeStepGroup, dataUserQuiz]);

  useEffect(() => {
    if (user?.accepted_terms_privacy) {
      refetch();
    }
  }, [refetch, user?.accepted_terms_privacy]);

  if (user?.accepted_terms_privacy && (isLoading || showStepIntro)) {
    return <StepIntro onAction={() => setShowStepIntro(false)} />;
  }

  return (
    <>
      <Stack sx={StyleWrap}>
        <Grid container justifyContent="center">
          <Grid xs={12} sm={10} md={8}>
            {renderStepper()}
          </Grid>
          <Grid item md={8} xs={10}>
            {!user?.accepted_terms_privacy && !finishedQuiz && (
              <StepTermsPrivacy />
            )}
            {user?.accepted_terms_privacy &&
              dataUserQuiz?.quiz &&
              formQuizInitialValues &&
              !dataUserQuiz.is_finished &&
              !finishedQuiz && (
                <Form
                  quiz={dataUserQuiz.quiz}
                  activeStep={activeStep}
                  question_id_last_answered={
                    dataUserQuiz.question_id_last_answered
                  }
                  initialValues={formQuizInitialValues}
                  setActiveStep={setActiveStep}
                  setFinishedQuiz={setFinishedQuiz}
                />
              )}
            {finishedQuiz && <StepFinished />}
          </Grid>
        </Grid>
      </Stack>
      <Stack
        sx={{
          position: {
            xs: "fixed",
          },
          display: {
            xs: "flex",
            md: "none",
          },
          bottom: {
            xs: 0,
          },
          left: {
            xs: 0,
          },
          right: {
            xs: 0,
          },
          height: {
            xs: 80,
            md: "auto",
          },
          width: {
            xs: "100%",
          },
          backgroundColor: {
            xs: theme.palette.background.paper,
            md: "transparent",
          },
          paddingTop: {
            md: 20,
            xs: 0,
          },
          paddingBottom: {
            md: 5,
            xs: 0,
          },
          zIndex: {
            xs: 1,
            md: 1,
          },
        }}
      >
        <Footer />
      </Stack>
    </>
  );
}
