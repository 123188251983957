import React, { ChangeEvent, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  Link,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useGlobalState } from "../../../../context";
import { ApiService } from "../../../../services";
import { stepsQuestionGroup } from "..";
import IconButton from "@mui/material/IconButton";
import { TbX } from "react-icons/tb";
import { TERMS } from "constants/endpoint";

export function StepTermsPrivacy() {
  const theme = useTheme();
  const [{ user }, dispatch] = useGlobalState();
  const [aceptTerms, setAceptTerms] = useState<boolean>(false);
  const [showDialogInfo, setShowDialogInfo] = useState<boolean>(false);

  const mutationCreateQuizAssociate = useMutation(
    "createQuizAssociate",
    () =>
      ApiService.UserQuiz.createQuizAssociate({
        user_id: user?.id!,
        quiz_id: 1,
      }),
    {
      onSuccess: () => {
        const response = {
          ...user,
          accepted_terms_privacy: true,
        } as User;

        dispatch({
          type: "SET_USER",
          data: response,
        });
      },
      onError: (resp) => {
        toast.error(
          "Error ao tentar aceitar os termos de privacidade! Por favor, tente novamente.",
          {
            autoClose: 10000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      },
    }
  );

  useEffect(() => {
    setShowDialogInfo(true);
  }, []);

  return (
    <Stack>
      <Stack marginY={8}>
        <Typography variant="h6" textAlign="center" color="text.primary">
          Autorização de uso dos dados
        </Typography>
      </Stack>

      <Stack
        justifyContent="space-between"
        sx={{
          flexDirection: {
            md: "row",
            xs: "column",
          },
          alignItems: {
            xs: "space-between",
            md: "center",
          },
          marginBottom: {
            xs: 10,
            md: 22,
          },
          paddingX: 2,
          paddingY: {
            xs: 1,
            md: 2,
          },
          backgroundColor: theme.palette.background.paper,
          borderRadius: 1,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              checked={aceptTerms}
              onChange={(value: ChangeEvent<HTMLInputElement>) =>
                setAceptTerms(value.target.checked)
              }
            />
          }
          label={
            <Typography color="text.primary" variant="body1">
              Li e aceito os termos de uso
            </Typography>
          }
        />

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{
            marginTop: {
              xs: 1,
              md: 0,
            },
            paddingBottom: {
              xs: 1,
              md: 0,
            },
          }}
        >
          <Link mr={2} variant="subtitle2" underline="hover" display="none">
            Resumo dos termos
          </Link>
          <Link
            variant="subtitle2"
            underline="hover"
            target="_blank"
            href={TERMS}
          >
            Termos de uso
          </Link>
        </Stack>
      </Stack>

      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <LoadingButton
            type="submit"
            fullWidth
            loading={mutationCreateQuizAssociate.isLoading}
            loadingPosition="center"
            variant="contained"
            size="large"
            disabled={!aceptTerms}
            onClick={() => mutationCreateQuizAssociate.mutate()}
          >
            Continuar
          </LoadingButton>
        </Grid>
      </Grid>

      <Dialog open={showDialogInfo} maxWidth="sm">
        <Stack sx={{ padding: 4 }}>
          {/* <Stack sx={{ position: "absolute", right: 12, top: 12 }}>
            <IconButton
              aria-label="delete"
              onClick={() => setShowDialogInfo(false)}
            >
              <TbX size={24} color={theme.palette.text.primary} />
            </IconButton>
          </Stack> */}

          <Stack mb={4}>
            <Typography variant="h5" gutterBottom>
              Questionário de perfil empreendedor
            </Typography>

            <Typography variant="body1">
              Olá, uma vez que tenha aceitado os termos de uso, estaremos
              prontos para começar o questionário e obter um entendimento mais
              profundo sobre você.
            </Typography>
          </Stack>

          <Typography variant="subtitle2" mb={2}>
            Tópicos quer eremos abordar:
          </Typography>

          {stepsQuestionGroup.map((item, idx) => (
            <Stack key={String(idx)} display="flex" flexDirection="row" mb={2}>
              <item.icon size={24} color={theme.palette.primary.main} />
              <Typography variant="body1" ml={2}>
                {item.title}
              </Typography>
            </Stack>
          ))}

          <Stack mt={2} sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              onClick={() => setShowDialogInfo(false)}
              sx={{ width: "50%" }}
            >
              Entendi
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </Stack>
  );
}
