import React from "react";

import { Breakpoint, Button, Typography, useTheme } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import LoadingButton from "@mui/lab/LoadingButton";
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';

type BtnColor =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning";

interface BaseDialogConfirmProps extends React.PropsWithChildren {
  show: boolean;
  title?: string;
  subtitle?: string;
  titleAgree?: string;
  titleCancel?: string;
  agreeIsLoading?: boolean;
  agreeColor?: BtnColor;
  cancelColor?: BtnColor;
  maxWidth?: Breakpoint;
  onAgree: () => void;
  onCancel: () => void;
  onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const BaseDialogConfirm = ({
  show,
  title,
  subtitle,
  titleAgree = "Confirmar",
  titleCancel = "Cancelar",
  onAgree,
  onCancel,
  onClose,
  agreeIsLoading,
  agreeColor = "primary",
  cancelColor = "secondary",
  maxWidth = "md",
  children,
}: BaseDialogConfirmProps) => {
  const theme = useTheme();

  return (
    <Dialog
      open={show}
      onClose={onClose}
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      keepMounted
      sx={{ backdropFilter: "blur(2px)" }}
      PaperProps={{ sx: { borderRadius: 2 } }}
    >
      <DialogContent sx={{ padding: 4 }}
        style={{ backgroundColor: theme.palette.background.paper }}
      >
        {!children ? (
          <>
            <Typography variant="h6" textAlign="start" gutterBottom>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="body1" gutterBottom>
                {subtitle}
              </Typography>
            )}
          </>
        ) : (
          children
        )}
      </DialogContent>
      <DialogActions sx={{
        paddingBottom: 4, paddingX: 4, gap: 2,
        backgroundColor: theme.palette.background.paper
      }}>
        <Button size="large" color={cancelColor} onClick={onCancel} fullWidth>
          {titleCancel}
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          color={agreeColor}
          onClick={onAgree}
          autoFocus
          fullWidth
          loadingPosition="center"
          loading={agreeIsLoading}
        >
          {titleAgree}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
