// GERENCIAMENTO DE ROTAS
// https://www.youtube.com/watch?v=oUZjO00NkhY

import React from "react";
import { useLocation, Outlet, Navigate } from "react-router-dom";
import { useGlobalState } from "../context";
import { getPathHome } from "../utils/route";
import { RoutesNames } from "constants/routes";

const PublicRoute = () => {
  const [{ user, isLoadingMe }] = useGlobalState();
  const location = useLocation();

  if (isLoadingMe) return null;

  if (user) {
    return (
      <Navigate
        to={getPathHome(user.user_type)}
        state={{ from: location }}
        replace
      />
    );
  }

  return <Outlet />;
};

const PrivateRouteCommon = () => {
  const [{ user, isLoadingMe }] = useGlobalState();
  const location = useLocation();

  if (isLoadingMe) return null;

  if (!user) {
    return (
      <Navigate
        to={RoutesNames.COMMON.LOGIN}
        state={{ from: location }}
        replace
      />
    );
  }

  // if (location.pathname === "/") {
  //   return (
  //     <Navigate
  //       to={getPathHome(user.user_type)}
  //       state={{ from: location }}
  //       replace
  //     />
  //   );
  // }

  return <Outlet />;
};

const PrivateRouteAdmin = () => {
  const [{ user, isLoadingMe }] = useGlobalState();
  const location = useLocation();

  if (isLoadingMe) return null;

  if (!user) {
    return (
      <Navigate
        to={RoutesNames.COMMON.LOGIN}
        state={{ from: location }}
        replace
      />
    );
  }

  if (location.pathname === "/") {
    return (
      <Navigate
        to={RoutesNames.ADMIN.HOME}
        state={{ from: location }}
        replace
      />
    );
  }

  if (!["ADMIN"].includes(user.user_type)) {
    return (
      <Navigate
        to={RoutesNames.COMMON.NO_ACCESS}
        state={{ from: location }}
        replace
      />
    );
  }

  return <Outlet />;
};

const PrivateRouteFranchisor = () => {
  const [{ user, isLoadingMe }] = useGlobalState();
  const location = useLocation();

  if (isLoadingMe) return null;

  if (!user) {
    return (
      <Navigate
        to={RoutesNames.COMMON.LOGIN}
        state={{ from: location }}
        replace
      />
    );
  }

  if (location.pathname === "/") {
    return (
      <Navigate
        to={RoutesNames.FRANCHISOR.HOME}
        state={{ from: location }}
        replace
      />
    );
  }

  if (!["FRANCHISOR"].includes(user.user_type)) {
    return (
      <Navigate
        to={RoutesNames.COMMON.NO_ACCESS}
        state={{ from: location }}
        replace
      />
    );
  }

  return <Outlet />;
};

const PrivateRouteAssociate = () => {
  const [{ user, isLoadingMe }] = useGlobalState();
  const location = useLocation();

  if (isLoadingMe) return null;

  if (!user) {
    return (
      <Navigate
        to={RoutesNames.COMMON.LOGIN}
        state={{ from: location }}
        replace
      />
    );
  }

  if (
    (!user.accepted_terms_privacy || !user.quiz_is_finished) &&
    location.pathname !== RoutesNames.ASSOCIATE.QUIZ
  ) {
    return (
      <Navigate
        to={RoutesNames.ASSOCIATE.QUIZ}
        state={{ from: location }}
        replace
      />
    );
  }

  if (
    user.accepted_terms_privacy &&
    user.quiz_is_finished &&
    location.pathname === RoutesNames.ASSOCIATE.QUIZ
  ) {
    return (
      <Navigate
        to={RoutesNames.ASSOCIATE.PROFILE}
        state={{ from: location }}
        replace
      />
    );
  }

  if (location.pathname === "/") {
    return (
      <Navigate
        to={RoutesNames.ASSOCIATE.PROFILE}
        state={{ from: location }}
        replace
      />
    );
  }

  if (!["ASSOCIATE"].includes(user.user_type)) {
    return (
      <Navigate
        to={RoutesNames.COMMON.NO_ACCESS}
        state={{ from: location }}
        replace
      />
    );
  }

  return <Outlet />;
};

const PrivateRouteCandidate = () => {
  const [{ user, isLoadingMe }] = useGlobalState();
  const location = useLocation();

  if (isLoadingMe) return null;

  if (!user) {
    return (
      <Navigate
        to={RoutesNames.COMMON.LOGIN}
        state={{ from: location }}
        replace
      />
    );
  }

  if (
    (!user.accepted_terms_privacy || !user.quiz_is_finished) &&
    location.pathname !== RoutesNames.CANDIDATE.QUIZ
  ) {
    return (
      <Navigate
        to={RoutesNames.CANDIDATE.QUIZ}
        state={{ from: location }}
        replace
      />
    );
  }

  if (
    user.accepted_terms_privacy &&
    user.quiz_is_finished &&
    location.pathname === RoutesNames.CANDIDATE.QUIZ
  ) {
    return (
      <Navigate
        to={RoutesNames.CANDIDATE.PROFILE}
        state={{ from: location }}
        replace
      />
    );
  }

  if (location.pathname === "/") {
    return (
      <Navigate
        to={RoutesNames.CANDIDATE.PROFILE}
        state={{ from: location }}
        replace
      />
    );
  }

  if (!["CANDIDATE"].includes(user.user_type)) {
    return (
      <Navigate
        to={RoutesNames.COMMON.NO_ACCESS}
        state={{ from: location }}
        replace
      />
    );
  }

  return <Outlet />;
};

interface AllowedRouteProps {
  allowedRoles: string[];
}
const AllowedRoute = ({ allowedRoles }: AllowedRouteProps) => {
  const [{ user, isLoadingMe }] = useGlobalState();
  const location = useLocation();

  if (isLoadingMe) return null;

  if (!user?.roles || user?.roles.length === 0) return <Outlet />;

  if (!user?.roles.find((role) => allowedRoles.includes(role))) {
    return (
      <Navigate
        to={RoutesNames.COMMON.NO_ACCESS}
        state={{ from: location }}
        replace
      />
    );
  }

  return <Outlet />;
};

const LoggedRoute = () => {
  const [{ user, isLoadingMe }] = useGlobalState();

  if (isLoadingMe) return null;

  if (user) {
    return <Navigate to={getPathHome(user.user_type)} replace />;
  }

  return <Outlet />;
};

export {
  PublicRoute,
  LoggedRoute,
  AllowedRoute,
  PrivateRouteAdmin,
  PrivateRouteCommon,
  PrivateRouteAssociate,
  PrivateRouteCandidate,
  PrivateRouteFranchisor,
};
