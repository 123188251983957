import React, { useMemo, useState } from "react";

import Typography from "@mui/material/Typography";

import { Box, Button, Link, Stack } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { ApiService } from "services";
import { toast } from "react-toastify";
import { BaseDialogConfirm, FormikTextField } from "components";
import { LoadingButton } from "@mui/lab";
import { useGlobalState } from "context";
import {
  validationSchemaData,
  validationSchemaDeleteAccount,
} from "./validationSchema";
import {
  FormikSelectField,
  FormikSelectFieldMenu,
} from "components/formik-select-field";

interface MutationUpdateMeProps {
  firstname: string;
  lastname: string;
  fullname: string;
  email?: string;
}

interface MutationDeleteAccountProps {
  reason: string;
  description?: string;
}

const INITIAL_VALUES: MutationDeleteAccountProps = {
  reason: "",
  description: "",
};

const REASON_LIST: FormikSelectFieldMenu[] = [
  {
    valeu: "Não quero mais adquirir uma franquia",
    title: "Não quero mais adquirir uma franquia",
  },
  {
    valeu: "Não quero mais empreender",
    title: "Não quero mais empreender",
  },
  {
    valeu: "Não entendi como esse serviço me ajuda",
    title: "Não entendi como esse serviço me ajuda",
  },
  {
    valeu: "Outros",
    title: "Outros",
  },
];

export const Data = () => {
  const [{ user }, dispatch] = useGlobalState();
  const [showDialogConfirm, setShowDialogConfirm] = useState<boolean>(false);
  const formikDeleteAccount = useFormik({
    initialValues: INITIAL_VALUES,
    validateOnBlur: false,
    validationSchema: validationSchemaDeleteAccount,
    onSubmit: (values) => {
      console.log(values);

      mutationDeleteAccount.mutate(values);
    },
  });

  const userFirstName = useMemo(() => {
    const partsOfName = (user?.fullname || "").split(" ");
    if (partsOfName.length > 0) return partsOfName[0];
    return "";
  }, [user?.fullname]);

  const userLastName = useMemo(() => {
    const partsOfName = (user?.fullname || "").split(" ");
    if (partsOfName.length > 1) return partsOfName[partsOfName.length - 1];
    return "";
  }, [user?.fullname]);

  const formikData = useFormik({
    initialValues: {
      firstname: user?.firstname! || userFirstName,
      lastname: user?.lastname! || userLastName,
      fullname: user?.fullname!,
      email: user?.email!,
    },
    enableReinitialize: true,
    validationSchema: validationSchemaData,
    validateOnBlur: false,
    onSubmit: (values) => {
      mutationUpdateMe.mutate({
        firstname: values.firstname,
        lastname: values.lastname,
        fullname: values.fullname,
      });
    },
  });

  const mutationDeleteAccount = useMutation<
    any,
    AxiosError,
    MutationDeleteAccountProps
  >("deleteMe", (data) => ApiService.Me.deleteMe(data), {
    onSuccess: () => {
      setShowDialogConfirm(false);
      formikDeleteAccount.resetForm();
      dispatch({ type: "SET_LOGOUT" });
      toast.success("Conta excluída com sucesso!", {
        autoClose: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    },
    onError: () => {
      toast.error("Erro ao excluir sua conta. Por favor, tente novamente", {
        autoClose: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    },
  });

  const mutationUpdateMe = useMutation<any, AxiosError, MutationUpdateMeProps>(
    "updateMe",
    (data) => ApiService.Me.updateMe(data),
    {
      onSuccess: () => {
        dispatch({
          type: "SET_USER",
          data: {
            ...user!,
            ...formikData.values,
          },
        });
        toast.success("Salvo com sucesso!", {
          autoClose: 10000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      },
      onError: () => {
        toast.error("Erro ao salvar os dados. Por favor, tente novamente", {
          autoClose: 10000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      },
    }
  );

  return (
    <>
      <Box marginTop={4} marginBottom={1}>
        <Typography
          color="text.primary"
          gutterBottom
          sx={{
            typography: {
              xs: "subtitle1",
              md: "h4",
            },
          }}
        >
          Meus dados
        </Typography>
      </Box>

      <FormikProvider value={formikData}>
        <Form>
          <Stack spacing={2}>
            <Stack flexDirection="row" gap={2}>
              <FormikTextField name="firstname" label="Primeiro nome" />
              <FormikTextField name="lastname" label="Último nome" />
            </Stack>
            <FormikTextField name="fullname" label="Nome completo *" />
            <FormikTextField name="email" label="Email" disabled />
            <Stack paddingTop={2}>
              <LoadingButton
                type="submit"
                loading={mutationUpdateMe.isLoading}
                loadingPosition="center"
                variant="contained"
                size="large"
              >
                Salvar alterações
              </LoadingButton>
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>

      {/* -------------- */}

      <Box marginTop={4} marginBottom={1}>
        <Typography
          color="text.primary"
          gutterBottom
          sx={{
            typography: {
              xs: "subtitle1",
              md: "h4",
            },
          }}
        >
          Excluir minha conta
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Ao excluir sua conta, todos os dados serão perdidos!
        </Typography>
      </Box>

      <Stack spacing={2}>
        <Stack paddingTop={2}>
          <Button
            type="submit"
            variant="outlined"
            color="error"
            size="large"
            onClick={() => setShowDialogConfirm(true)}
          >
            Continuar com a exclusão
          </Button>
        </Stack>
      </Stack>

      <BaseDialogConfirm
        show={showDialogConfirm}
        onAgree={() => formikDeleteAccount.submitForm()}
        titleAgree="Excluir conta"
        agreeColor="error"
        agreeIsLoading={mutationDeleteAccount.isLoading}
        onCancel={() => {
          setShowDialogConfirm(false);
          formikDeleteAccount.resetForm();
        }}
        onClose={() => {
          setShowDialogConfirm(false);
          formikDeleteAccount.resetForm();
        }}
      >
        <Typography
          variant="h5"
          sx={{
            typography: {
              xs: "subtitle1",
              md: "h5",
            },
          }}
          gutterBottom
        >
          Poderia nos ajudar a fazer melhor da próxima?
        </Typography>

        <Link underline="hover" variant="subtitle2">
          Quer tirar algum dúvida conosco antes?
        </Link>

        <FormikProvider value={formikDeleteAccount}>
          <Form style={{ height: "100%" }}>
            <Stack spacing={2} marginTop={4}>
              <FormikSelectField
                name="reason"
                label="Principal razão *"
                menuList={REASON_LIST}
              />
              <FormikTextField
                name="description"
                label="Mais detalhes (Opcional)"
                rows={3}
                multiline
              />
            </Stack>
          </Form>
        </FormikProvider>
      </BaseDialogConfirm>
    </>
  );
};
