import React from "react";
import { useField } from "formik";

import Radio from "@mui/material/Radio";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { SxProps, Theme, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useTheme } from "@mui/material";

export interface FormikRadioGroupItem {
  value: string;
  title: string;
}

type FormikRadioGroupProps = {
  name: string;
  label?: string;
  sxRadioGroup?: SxProps<Theme>;
  sxRadio?: SxProps<Theme>;
  radioList: FormikRadioGroupItem[];
} & RadioGroupProps;
export const FormikRadioGroup = ({
  name,
  label,
  sxRadioGroup,
  sxRadio,
  radioList,
  ...props
}: FormikRadioGroupProps) => {
  const [
    field,
    meta,
    // helpers
  ] = useField(name);
  const theme = useTheme();

  return (
    <FormControl>
      {label && (
        <Stack marginBottom={1}>
          <FormLabel
            sx={{ color: ` ${theme.palette.text.primary} !important` }}
            id={`formlabel-${name}`}
          >
            {label}
          </FormLabel>
        </Stack>
      )}

      <React.Fragment>
        <RadioGroup {...field} {...props} name={field.name} sx={sxRadioGroup}>
          <Stack spacing={2}>
            {radioList.map((item) => (
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={<Radio />}
                label={
                  <Typography variant="body1" color="text.primary">
                    {item.title}
                  </Typography>
                }
                sx={sxRadio}
              />
            ))}
          </Stack>
        </RadioGroup>

        {meta.touched && Boolean(meta.error) && (
          <FormHelperText sx={{ color: "#fe494cff !important" }}>
            {meta.error}
          </FormHelperText>
        )}
      </React.Fragment>
    </FormControl>
  );
};
