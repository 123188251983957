export const delay = (delay: number) => {
  return new Promise(function (resolve) {
    setTimeout(resolve, delay)
  })
}

export const jumpToReleventElement = (id: string, yOffset?: number) => {
  const elementPosition = document.getElementById(id)?.offsetTop
  if (!elementPosition) return

  window.scrollTo({
    top: elementPosition + (yOffset || 0),
    behavior: 'smooth',
  })
}
