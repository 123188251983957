import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useMutation } from "react-query";
import { Form, FormikProvider, useFormik } from "formik";

import { toast } from "react-toastify";

import { validationSchemaLogin } from './validationSchema';
import { ApiService } from "../../services";
import LoadingButton from "@mui/lab/LoadingButton";
import { AxiosError } from "axios";
import { useGlobalState } from "../../context";
import { RoutesNames } from "constants/routes";
import { Button, useTheme } from "@mui/material";

import { FormikTextField } from "components/formik-text-field";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

interface MutationAuthProps {
  fullname: string;
  email: string;
  password: string;
  confirm_password: string;
  type_id: number | string;
}

const INITIAL_VALUES_LOGIN: Pick<MutationAuthProps, "email" | "password"> = {
  email: "",
  password: "",
};

interface AuthLoginProps {
  onSignup: () => void
}

export const AuthLogin = ({ onSignup }: AuthLoginProps) => {
  const theme = useTheme();
  const navigate = useNavigate()
  const [, dispatch] = useGlobalState();
  const recaptchaRef = React.createRef<any>();
  const [loadingLogin, setLoadingLogin] = useState<boolean>(false);

  const isValidGoogleRecaptcha = async () => {
    return true;
    // if (process.env.REACT_APP_NODE_ENV === "development") return true;

    // const responseToken = await recaptchaRef.current.executeAsync();
    // const responseGoogleReCaptcha = await ApiService.Auth.googleReCaptcha({
    //   responseToken,
    // });

    // if (
    //   !responseGoogleReCaptcha.success ||
    //   responseGoogleReCaptcha.score <= 0.4
    // ) {
    //   return false;
    // }
    // return true;
  };

  const formikLogin = useFormik({
    initialValues: INITIAL_VALUES_LOGIN,
    validationSchema: validationSchemaLogin,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setLoadingLogin(true);
      const isValidRecaptcha = await isValidGoogleRecaptcha();

      if (!isValidRecaptcha) {
        setLoadingLogin(false);
        toast.error("Login bloqueado! Por favor, entre em contato.", {
          autoClose: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }

      mutationLogin.mutate(values);
    },
  });

  const mutationLogin = useMutation<
    User,
    AxiosError,
    Pick<MutationAuthProps, "email" | "password">
  >("login", (data) => ApiService.Auth.login(data), {
    onSuccess: (resp: User) => {
      dispatch({
        type: "SET_USER",
        data: resp,
      });
      setLoadingLogin(false);
      formikLogin.resetForm();
    },
    onError: (resp: AxiosError) => {
      setLoadingLogin(false);
      const data = resp.response?.data as ApiErrorResponse;
      toast.error(data.message, {
        autoClose: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    },
  });

  return (
    <Stack
      id='login'
      sx={{
        width: { xs: '100%', md: 600 },
        backgroundColor: theme.palette.background.paper,
        paddingY: 5,
        paddingX: { xs: 2, sm: 5 },
        borderRadius: 2,
      }}>
      <Typography variant="h6" textAlign="center" color='text.primary'>
        Já tenho uma conta
      </Typography>

      <Stack mb={2} />

      <FormikProvider value={formikLogin}>
        <Form>
          <Stack
            spacing={2}
            justifyContent="center"
          >
            <FormikTextField name="email" label="Email" />
            <FormikTextField
              name="password"
              label="Senha"
              type="password"
            />
            <Button
              onClick={() => navigate(RoutesNames.COMMON.FORGOT_PASSWORD)}
            >
              Esqueci minha senha
            </Button>
          </Stack>

          <Grid container
            pt={4}
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <LoadingButton
                fullWidth
                loadingPosition="center"
                variant="outlined"
                size="large"
                disableElevation
                onClick={onSignup}
              >
                Criar conta
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                type="submit"
                fullWidth
                loading={loadingLogin}
                loadingPosition="center"
                variant="contained"
                size="large"
                disableElevation
              >
                Entrar
              </LoadingButton>
            </Grid>
          </Grid>

        </Form>
      </FormikProvider>

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY_SITE || ""}
      />
    </Stack>
  );
};

