// import { indigo, green } from "@mui/material/colors";
// import { ListItemButtonClasses } from '@mui/material/ListItemButton'
import { grey } from "@mui/material/colors";
import { PaletteOptions, createTheme } from "@mui/material/styles";
import Sora from "../assets/font/sora.woff2";
import { PaletteMode } from "@mui/material";
// import Sora from "../assets/font/Sora-Regular.ttf";

import type {} from "@mui/lab/themeAugmentation";

const borderRadius = 8;

interface ThemeProps {
  mode: PaletteMode;
}

declare module "@mui/material/styles" {
  interface Palette {
    btnSuccess: Palette["primary"];
    btnError: Palette["primary"];
    btnInfo: Palette["primary"];
    themeBackgroundColor: Palette["primary"];
    typography900: Palette["primary"];
    typography500: Palette["primary"];
    themeBlackColor: Palette["primary"];
    themeWhiteColor: Palette["primary"];
    themeGrey666Color: Palette["primary"];
    themeGrey999Color: Palette["primary"];
    themeGreyCCCColor: Palette["primary"];
    themeGreyE5Color: Palette["primary"];
    themeGreyFAColor: Palette["primary"];
    themeBlue900Color: Palette["primary"];
    themeBlue500Color: Palette["primary"];
    themeBlue100Color: Palette["primary"];
    themeRed900Color: Palette["primary"];
    themeRed500Color: Palette["primary"];
    themeRed100Color: Palette["primary"];
    borderColor: Palette["primary"];
    borderMediumColor: Palette["primary"];
  }

  interface PaletteOptions {
    btnSuccess?: PaletteOptions["primary"];
    btnError?: PaletteOptions["primary"];
    btnInfo?: PaletteOptions["primary"];
    themeBackgroundColor?: PaletteOptions["primary"];
    typography900: PaletteOptions["primary"];
    typography500: PaletteOptions["primary"];
    themeBlackColor: PaletteOptions["primary"];
    themeWhiteColor: PaletteOptions["primary"];
    themeGrey666Color: PaletteOptions["primary"];
    themeGrey999Color: PaletteOptions["primary"];
    themeGreyCCCColor: PaletteOptions["primary"];
    themeGreyE5Color: PaletteOptions["primary"];
    themeGreyFAColor: PaletteOptions["primary"];
    themeBlue900Color: PaletteOptions["primary"];
    themeBlue500Color: PaletteOptions["primary"];
    themeBlue100Color: PaletteOptions["primary"];
    themeRed900Color: PaletteOptions["primary"];
    themeRed500Color: PaletteOptions["primary"];
    themeRed100Color: PaletteOptions["primary"];
    borderColor: PaletteOptions["primary"];
    borderMediumColor: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    btnSuccess: true;
    btnError: true;
    btnInfo: true;
    themeBackgroundColor: true;
    typography900: true;
    typography500: true;
    themeBlackColor: true;
    themeWhiteColor: true;
    themeGrey666Color: true;
    themeGrey999Color: true;
    themeGreyCCCColor: true;
    themeGreyE5Color: true;
    themeGreyFAColor: true;
    themeBlue900Color: true;
    themeBlue500Color: true;
    themeBlue100Color: true;
    themeRed900Color: true;
    themeRed500Color: true;
    themeRed100Color: true;
    borderColor: true;
    borderMediumColor: true;
  }
}

const themeColorCommun = {
  grey666: "#666666",
  grey999: "#999999",
  greyCCC: "#cccccc",
  greyE5: "#e5e5e5",
  greyFA: "#fafafa",
};
const themeLightColor = {
  ...themeColorCommun,
  background: "#ffffff",
  backgroundPaper: "#fafafa",
  typography900: "#000000",
  typography500: "#999999",
  blue900: "#211456",
  blue500: "#b6b2c5",
  blue100: "#d5d3dd",
  red900: "#ff0000",
  red500: "#f8acac",
  red100: "#f7d1d0",
  borderColor: "#fafafa",
  borderMediumColor: grey[200],
};
const themeDarkColor = {
  ...themeColorCommun,
  background: "#000000",
  backgroundPaper: "#1b1b1b",
  typography900: "#ffffff",
  typography500: "#999999",
  blue900: "#211456",
  blue500: "#140c34",
  blue100: "#110a2b",
  red900: "#ff0000",
  red500: "#4d0000",
  red100: "#260000",
  borderColor: "#1b1b1b",
  borderMediumColor: grey[500],
};

const lightConfig: Partial<PaletteOptions> = {
  primary: {
    main: "#0062D4",
  },
  secondary: {
    main: themeLightColor.red900,
  },
  divider: themeLightColor.backgroundPaper,
  text: {
    primary: themeLightColor.typography900,
    secondary: themeLightColor.typography500,
    disabled: grey[400],
  },
  background: {
    default: themeLightColor.background,
    paper: themeLightColor.backgroundPaper,
  },
};
const darkConfig: Partial<PaletteOptions> = {
  primary: {
    main: "#0062D4",
  },
  secondary: {
    main: themeDarkColor.red900,
  },
  divider: themeDarkColor.backgroundPaper,
  text: {
    primary: themeDarkColor.typography900,
    secondary: themeDarkColor.typography500,
    disabled: grey[400],
  },
  background: {
    default: themeDarkColor.background,
    paper: themeDarkColor.backgroundPaper,
  },
};

export const themeConfig = ({ mode }: ThemeProps) => {
  let theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Sora, -apple-system, Roboto, Segoe UI, Arial, Helvetica",
        textTransform: "none",
      },
      fontWeightLight: 200,
      fontWeightRegular: 300,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    shape: {
      borderRadius,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Sora';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Sora'), local('Sora'), url(${Sora}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            "&:before": {
              backgroundColor: "transparent",
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 15,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            borderRadius: 24,

            "&.Mui-disabled": {
              ...(mode === "dark" ? { background: "#222" } : {}),
              ...(mode === "dark" ? { color: "#444" } : {}),
            },
          },
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            "> span": {
              ...(mode === "dark"
                ? { color: themeDarkColor.typography500 }
                : {}),
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          // https://codesandbox.io/s/checkbox-background-color-vcr6e?fontsize=14&file=/src/index.js:167-587
          // root: {
          //   color: orange[100],
          //   "&.Mui-checked": {
          //     color: orange[600],
          //   },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "light" ? "#fff" : grey[800],
            // "& .MuiOutlinedInput-notchedOutline": {},
            "& .MuiOutlinedInput-input": {
              backgroundColor: mode === "light" ? "#fff" : grey[800],
              borderRadius,
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${mode === "light" ? grey[200] : grey[900]}`,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${mode === "light" ? grey[800] : grey[50]}`,
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            // '&& .Mui-selected, && .Mui-selected:hover': {
            //   backgroundColor: green[50],
            //   '&, & .MuiListItemIcon-root': {
            //     color: green[500],
            //   },
            // },
            // '& .MuiListItemButton-root:hover': {
            //   backgroundColor: grey[100],
            //   '&, & .MuiListItemIcon-root': {
            //     color: 'yellow',
            //   },
            // },
            "& .MuiListItemButton-root": {
              borderRadius,
            },
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            border:
              mode === "light"
                ? `1px solid ${themeLightColor.borderColor}`
                : `1px solid ${themeDarkColor.borderColor}`,
            // backgroundColor:
            //   mode === 'light'
            //     ? themeLightColor.backgroundPaper
            //     : themeDarkColor.backgroundPaper,
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            zIndex: 0,
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            height: 54,
            "& .MuiTableCell-head": {
              height: 54,
              // backgroundColor:
              //   mode === 'light'
              //     ? themeLightColor.backgroundPaper
              //     : themeDarkColor.backgroundPaper,
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottomStyle: "dashed",
            borderColor:
              mode === "light"
                ? themeLightColor.borderMediumColor
                : themeDarkColor.borderColor,
            // ...(mode === 'light'
            //   ? { borderColor: themeLightColor.borderMediumColor }
            //   : {}),
          },
        },
      },
      // MuiFormHelperText: {
      //   styleOverrides: {
      //     root: {
      //       color: "#fe494cff !important",
      //     },
      //   },
      // },
    },
  });

  theme = createTheme(theme, {
    ...theme,
    palette: {
      error: {
        main: "#fe494cff",
      },
      mode,
      ...(mode === "light" ? lightConfig : darkConfig),
      btnSuccess: theme.palette.augmentColor({
        color: {
          main: "#00BF2A",
          light: "#00BF2A",
          dark: "#00BF2A",
          contrastText: "#FFF",
        },
        name: "btnSuccess",
      }),
      btnError: theme.palette.augmentColor({
        color: {
          main: "#EE4646",
          light: "#EE4646",
          dark: "#EE4646",
          contrastText: "#FFF",
        },
        name: "btnError",
      }),
      btnInfo: theme.palette.augmentColor({
        color: {
          main: "#7BB8FF",
          light: "#7BB8FF",
          dark: "#7BB8FF",
          contrastText: "#FFF",
        },
        name: "btnInfo",
      }),
      borderColor: theme.palette.augmentColor({
        color: {
          main:
            mode === "light"
              ? themeLightColor.borderColor
              : themeDarkColor.borderColor,
        },
        name: "borderColor",
      }),
      borderMediumColor: theme.palette.augmentColor({
        color: {
          main:
            mode === "light"
              ? themeLightColor.borderMediumColor
              : themeDarkColor.borderMediumColor,
        },
        name: "borderMediumColor",
      }),
      themeBlue900Color: theme.palette.augmentColor({
        color: {
          main:
            mode === "light" ? themeLightColor.blue900 : themeDarkColor.blue900,
        },
        name: "themeBlue900Color",
      }),
    },
  });

  return theme;
};
