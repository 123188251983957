import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import { donwloadPdfFile } from "utils/download-pdf-file";
import { TERMS } from "constants/endpoint";

interface FooterProps {
  verticalPadding?: number;
}
export const Footer = ({ verticalPadding = 0 }: FooterProps) => {
  return (
    <Grid container paddingY={verticalPadding}>
      <Grid
        item
        xs={12}
        justifyContent="center"
        display="flex"
        textAlign="center"
      >
        <Stack direction="column">
          <Link
            variant="caption"
            underline="hover"
            fontWeight={500}
            href="mailto:contato@datarari.com.br"
          >
            Contato para o suporte
          </Link>
          <Typography variant="caption" color="text.primary" my={1}>
            © Copyright 2023 - Todos os direitos reservados
          </Typography>

          <Link
            variant="caption"
            underline="hover"
            fontWeight={500}
            target="_blank"
            href={TERMS}
          >
            Termos de uso e privacidade
          </Link>
        </Stack>
      </Grid>
    </Grid>
  );
};
