import React from "react";

import { Player } from "@lottiefiles/react-lottie-player";
import LottieNoContent from "../../assets/lottie/no-content.json";
import { Stack, Typography } from "@mui/material";

interface NoContentProps {
  title?: string;
  subtitle?: string;
  size?: number;
}
export const NoContent = ({
  title = "Nenhum arquivo salvo",
  subtitle = "Todos os arquivos salvos serão listados aqui",
  size = 200,
}: NoContentProps) => {
  return (
    <Stack>
      <Player
        autoplay
        src={LottieNoContent}
        keepLastFrame
        speed={0.8}
        style={{
          height: `${size}px`,
          width: "100%",
        }}
      />

      <Typography
        color='text.primary'
        textAlign="center"
        sx={{
          typography: {
            xs: "subtitle1",
            md: "h6",
          },
        }}
      >
        {title}
      </Typography>

      {subtitle && (
        <Typography
          color='text.secondary'
          textAlign="center" variant="body2">
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
};
