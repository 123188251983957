import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Player } from "@lottiefiles/react-lottie-player";
import Congratulation from "../../assets/lottie/congratulation.json";
import Error from "../../assets/lottie/error.json";
import { useQuery } from "react-query";

import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { ApiService } from "services";
import { AxiosError } from "axios";
import { RoutesNames } from "constants/routes";

export const EnableAccount = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");

  const { isError, isLoading } = useQuery<undefined, AxiosError>(
    "enableAccount",
    async () => ApiService.Auth.enableAccount({ token: token || "" }),
    {
      onSuccess: (resp) => { },
      onError: (resp: AxiosError) => {
        const data = resp.response?.data as ApiErrorResponse;
        setMessage(data.message || "");
      },
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) return null;

  if (!isLoading && isError) {
    return (
      <Stack justifyContent="center" mt={5} paddingX={2}>
        <Player
          autoplay
          src={Error}
          keepLastFrame
          speed={0.8}
          style={{
            height: "200px",
            width: "100%",
          }}
        />
        <Box mb={3} />

        <Typography variant="h4" textAlign="center" gutterBottom color='text.primary'>
          Erro na validação
        </Typography>

        <Typography variant="body1" textAlign="center" color='text.secondary'>
          {message}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack justifyContent="center" mt={5} paddingX={2}>
      <Player
        autoplay
        src={Congratulation}
        keepLastFrame
        speed={0.8}
        style={{
          height: "200px",
          width: "100%",
        }}
      />
      <Box mb={3} />

      <Typography variant="h4" textAlign="center" gutterBottom color='text.primary'>
        Muito obrigado!
      </Typography>

      <Typography variant="body1" textAlign="center" marginBottom={2} color='text.secondary'>
        Seu e-mail foi confirmado com sucesso
      </Typography>

      <Button
        onClick={() => navigate(RoutesNames.COMMON.LOGIN)}
      >
        Fazer login
      </Button>
    </Stack>
  );
};
