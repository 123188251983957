import React, {
  createContext,
  useContext,
  useReducer,
  Dispatch,
  useEffect,
} from "react";
import {
  ID_LOCAL_STORAGE_ACCESS_TOKEN,
  ID_LOCAL_STORAGE_THEME_MODE,
} from "../constants/cookie-local-storage";
import { useQuery } from "react-query";
import { ApiService } from "services";
import { delay } from "utils/tricks";
import { PaletteMode } from "@mui/material";

interface RootState {
  user: User | undefined;
  isLoadingApp: boolean;
  isLoadingMe: boolean;
  themeMode: PaletteMode;
}

type Actions =
  | { type: "SET_USER"; data: User | undefined }
  | { type: "SET_IS_LOADING_APP"; data: boolean }
  | { type: "SET_LOGOUT" }
  | { type: "SET_LOADING_ME"; data: boolean }
  | { type: "SET_THEME_MODE"; data: PaletteMode };

const initialState: RootState = {
  user: undefined,
  isLoadingApp: false,
  isLoadingMe: true,
  themeMode: "light",
};

const reducer = (state: RootState, action: Actions) => {
  switch (action.type) {
    case "SET_USER": {
      return { ...state, user: action.data };
    }
    case "SET_IS_LOADING_APP": {
      return { ...state, isLoadingApp: action.data };
    }
    case "SET_LOADING_ME": {
      return { ...state, isLoadingMe: action.data };
    }
    case "SET_LOGOUT": {
      localStorage.removeItem(ID_LOCAL_STORAGE_ACCESS_TOKEN);
      return { ...state, user: undefined };
    }
    case "SET_THEME_MODE": {
      return { ...state, themeMode: action.data };
    }

    default:
      return state;
  }
};

type GlobalStateContextData = [RootState, Dispatch<Actions>];

const GlobalStateContext = createContext<GlobalStateContextData | undefined>(
  undefined
);

interface GlobalStateProviderProps extends React.PropsWithChildren {}

export const GlobalStateProvider = ({ children }: GlobalStateProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { refetch } = useQuery<User>(
    "getMe",
    async () => ApiService.Me.getMe(),
    {
      onSuccess: (resp) => {
        dispatch({
          type: "SET_USER",
          data: resp,
        });
        dispatch({
          type: "SET_LOADING_ME",
          data: false,
        });
      },
      onError: () => {
        dispatch({ type: "SET_LOGOUT" });
        localStorage.clear();
      },
      enabled: false,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const callLater = async () => {
    await delay(1000);

    dispatch({
      type: "SET_IS_LOADING_APP",
      data: false,
    });
  };

  useEffect(() => {
    // callLater();

    const token = localStorage.getItem(ID_LOCAL_STORAGE_ACCESS_TOKEN);
    const themeMode = localStorage.getItem(
      ID_LOCAL_STORAGE_THEME_MODE
    ) as PaletteMode;

    dispatch({ type: "SET_THEME_MODE", data: themeMode || "light" });

    if (!token) {
      // localStorage.clear();

      dispatch({
        type: "SET_LOADING_ME",
        data: false,
      });

      return;
    }

    if (!state.user) {
      refetch();
      return;
    }
  }, [refetch, state.user]);

  // useEffect(() => {
  //   const user = localStorage.getItem(ID_LOCAL_STORAGE_USER);
  //   if (user) {
  //     dispatch({
  //       type: "SET_USER",
  //       data: JSON.parse(user) as User,
  //     });
  //   }
  //   dispatch({
  //     type: "SET_LOADING_ME",
  //     data: false,
  //   });
  // }, []);

  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);

  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }

  return context;
};
