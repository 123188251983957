import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";

import {
  Footer,
  AuthSignup,
  HeaderGlobal,
  FormikTextField,
  FormikTextFieldMaskPhone,
} from "components";
import { Button, Link } from "@mui/material";

import { HowWorksLightSvg } from "assets/svg/how-works-light";
import { useGlobalState } from "context";
import { VoiceSVG } from "assets/svg/voice";
import { useTheme } from "@mui/material";
import { DatabaseSVG } from "assets/svg/database";
import { MemorySVG } from "assets/svg/memory";
import { PersonSVG } from "assets/svg/person";
import { HowWorksDarkSvg } from "assets/svg/how-works-dark";
import { AcuteSVG } from "assets/svg/acute";
import { ChoiceSVG } from "assets/svg/choice";
import { ExplorationSVG } from "assets/svg/exploration";
import { ShieldSVG } from "assets/svg/shield";
import { ApiService } from "services";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { LoadingButton } from "@mui/lab";
import { Form, FormikProvider, useFormik } from "formik";
import { validationSchemaContact } from "./validationSchema";
import { TbChevronsDown, TbMail, TbPhone } from "react-icons/tb";
import { useMediaQuery } from "@mui/material";
import { IMAGES } from "constants/images";
import { jumpToReleventElement } from "utils/tricks";
import { toast } from "react-toastify";
import { RoutesNames } from "constants/routes";

interface HowWorksList {
  icon: (fill: string, size?: number) => React.ReactElement;
  title: string;
  description: string;
}
interface HowWorksListItem extends HowWorksList {
  cardIndex: number;
}
const HOW_WORKS_LIST: HowWorksList[] = [
  {
    icon: (fill, size) => <VoiceSVG fill={fill} size={size} />,
    title: "Entre em contato",
    description:
      "Nossa equipe irá fornecer todos os detalhes do que será necessário para uma ánalise bem sucedida bem como tirar todas suas dúvidas",
  },
  {
    icon: (fill, size) => <DatabaseSVG fill={fill} size={size} />,
    title: "Forneça os dados do nosso portal",
    description:
      "Através dos dados de performance e de perfil dos associados nosso sistema será preparado para a próxima etapa",
  },
  {
    icon: (fill, size) => <MemorySVG fill={fill} size={size} />,
    title: "Construção do Modelo",
    description:
      "O Mappea irá construir um modelo que permite estimar o quão adequada é uma nova pessoa  baseado nos dados de performance fornecidos previamente",
  },
  {
    icon: (fill, size) => <PersonSVG fill={fill} size={size} />,
    title: "Análise dinÂmica candidatos",
    description:
      "Uma vez fornecida uma lista de candidatos o Mappea disponibiliza um relatório destacando os melhores candidatos para o perfil de sua franquia",
  },
];
const POTENTIALIZE_FRANCHISE_LIST: HowWorksList[] = [
  {
    icon: (fill, size) => <AcuteSVG fill={fill} size={size} />,
    title: "OTIMIZE SEU TEMPO",
    description: "Facilite a escolha de franqueados",
  },
  {
    icon: (fill, size) => <ChoiceSVG fill={fill} size={size} />,
    title: "Assertividade",
    description:
      "Identifique os franqueados certos para seu perfil de franquia",
  },
  {
    icon: (fill, size) => <ExplorationSVG fill={fill} size={size} />,
    title: "redução de turnover",
    description: "Franquias mais saudáveis e menos turnovers",
  },
  {
    icon: (fill, size) => <ShieldSVG fill={fill} size={size} />,
    title: "privacidade de dados",
    description:
      "Todos os dados dos clientes são fisicamente separados e encriptados",
  },
];

interface MutationContactProps {
  name: string;
  email: string;
  phoneNumber?: string;
  message: string;
}
const INITIAL_VALUES_CONTACT: MutationContactProps = {
  name: "",
  email: "",
  phoneNumber: "",
  message: "",
};
export const Contact = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);

  const formikContact = useFormik({
    initialValues: INITIAL_VALUES_CONTACT,
    validationSchema: validationSchemaContact,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setLoading(true);
      mutationContact.mutate(values);
    },
  });

  const mutationContact = useMutation<
    undefined,
    AxiosError,
    MutationContactProps
  >("sendEmail", (data) => ApiService.Contact.sendEmail(data), {
    onSuccess: () => {
      setLoading(false);
      formikContact.resetForm();
      toast.success("E-mail enviado com sucesso!", {
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    },
    onError: (resp) => {
      setLoading(false);
      toast.error("Erro ao enviar e-mail! Por favor, tente novamente.", {
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    },
  });

  return (
    <Stack id="contact">
      <Typography
        variant="h6"
        color="text.primary"
        fontWeight={200}
        textAlign="center"
      >
        CONTATO
      </Typography>

      <Box mb={2} />

      <Grid container spacing={6} sx={{ paddingX: { xs: 1, sm: 5 } }}>
        <Grid item xs={12} md={6}>
          <Stack
            sx={{
              backgroundColor: theme.palette.background.paper,
              paddingY: 5,
              paddingX: {
                xs: 2,
                sm: 5,
              },
              borderRadius: 1,
              minHeight: 536,
            }}
          >
            <FormikProvider value={formikContact}>
              <Form>
                <Stack spacing={2}>
                  <FormikTextField name="name" label="Nome" />
                  <FormikTextField name="email" label="E-mail" />

                  <FormikTextFieldMaskPhone
                    name="phoneNumber"
                    label="DDD+Telefone / Celular"
                  />

                  <FormikTextField
                    name="message"
                    label="Mensagem"
                    rows={5}
                    multiline
                    inputProps={{ maxLength: 360 }}
                  />

                  <Stack
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography variant="caption" color="text.secondary">
                      {formikContact.values.message.length}/360
                    </Typography>

                    <LoadingButton
                      type="submit"
                      loading={loading}
                      loadingPosition="center"
                      variant="contained"
                      size="large"
                      sx={{ mt: 2 }}
                    >
                      Enviar mensagem
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Form>
            </FormikProvider>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack
            sx={{
              backgroundColor: theme.palette.background.paper,
              paddingY: 6,
              paddingX: {
                xs: 3,
                sm: 6,
              },
              borderRadius: 1,
              minHeight: 536,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box display="flex" flexDirection="row" mb={4}>
              <Box mr={5}>
                <TbPhone size={24} color={theme.palette.text.secondary} />
              </Box>
              <Typography color="text.secondary" variant="body1" mr={3}>
                +55 81 98759-3444
              </Typography>
            </Box>

            <Box display="flex" flexDirection="row">
              <Box mr={5}>
                <TbMail size={24} color={theme.palette.text.secondary} />
              </Box>
              <Link
                href="mailto:contato@mappea.com"
                underline="hover"
                variant="body1"
              >
                contato@mappea.com
              </Link>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

const HowWorks = () => {
  const theme = useTheme();

  const Item = ({ cardIndex, icon, title, description }: HowWorksListItem) => {
    const [{ themeMode }] = useGlobalState();

    return (
      <Stack
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Stack position="relative" justifyContent="center" alignItems="center">
          {themeMode === "dark" && <HowWorksDarkSvg size={260} />}
          {themeMode === "light" && <HowWorksLightSvg size={260} />}
          <Stack
            position="absolute"
            alignItems="center"
            pt={12}
            sx={{ width: "100%", height: "100%" }}
          >
            {icon(theme.palette.text.primary, 60)}
          </Stack>
        </Stack>

        <Stack
          justifyContent="center"
          display="flex"
          textAlign="center"
          sx={{ width: { sx: "100%", md: "85%" } }}
        >
          <Box
            display="flex"
            flexDirection="row"
            mt={2}
            mb={1}
            justifyContent="center"
          >
            <Typography
              variant="subtitle2"
              color="text.primary"
              textAlign="center"
            >
              {cardIndex}. {title.toUpperCase()}
            </Typography>
          </Box>
          <Typography variant="caption" color="text.primary" textAlign="center">
            {description}
          </Typography>
        </Stack>

        {cardIndex === 1 && (
          <Stack
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={3}
            sx={{ cursor: "pointer" }}
            onClick={() => jumpToReleventElement("contact", -120)}
          >
            <Button
              variant="contained"
              onClick={() => jumpToReleventElement("contact", -120)}
              disableElevation
              size="small"
              startIcon={<TbPhone />}
              sx={{ width: 120 }}
            >
              Contato
            </Button>
            <Box mb={1} />
            <TbChevronsDown size={24} color={theme.palette.primary.main} />
          </Stack>
        )}
      </Stack>
    );
  };

  return (
    <Stack>
      <Typography
        variant="h6"
        color="text.primary"
        textAlign="center"
        fontWeight={200}
      >
        COMO FUNCIONA?
      </Typography>

      <Box mb={2} />

      <Grid container spacing={6} px={6}>
        {HOW_WORKS_LIST.map((item, index) => (
          <Grid item xs={12} sm={6} md={3}>
            {Item({
              icon: item.icon,
              cardIndex: index + 1,
              title: item.title,
              description: item.description,
            })}
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

const PotentializeFranchise = () => {
  const theme = useTheme();

  const Item = ({ cardIndex, icon, title, description }: HowWorksListItem) => {
    const [{ themeMode }] = useGlobalState();

    return (
      <Stack
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: theme.palette.background.paper }}
        borderRadius={1}
        p={3}
        minHeight={336}
      >
        <Stack position="relative" justifyContent="center" alignItems="center">
          {themeMode === "dark" && <HowWorksDarkSvg size={200} />}
          {themeMode === "light" && <HowWorksLightSvg size={200} />}
          <Stack
            position="absolute"
            alignItems="center"
            pt={9}
            sx={{ width: "100%", height: "100%" }}
          >
            {icon(theme.palette.text.primary, 60)}
          </Stack>
        </Stack>
        <Box display="flex" flexDirection="row" mt={2} mb={1}>
          <Typography variant="subtitle2" color="text.primary">
            {cardIndex}.
          </Typography>
          <Box mr={1} />
          <Typography variant="subtitle2" color="text.primary">
            {title.toUpperCase()}
          </Typography>
        </Box>
        <Typography variant="caption" color="text.primary" textAlign="center">
          {description}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      <Typography
        variant="h6"
        color="text.primary"
        textAlign="center"
        fontWeight={200}
      >
        POTENCIALIZE SUA FRANQUIA
      </Typography>

      <Box mb={2} />

      <Grid container spacing={6} px={6}>
        {POTENTIALIZE_FRANCHISE_LIST.map((item, index) => (
          <Grid item xs={12} sm={6} md={3}>
            {Item({
              icon: item.icon,
              cardIndex: index + 1,
              title: item.title,
              description: item.description,
            })}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export const Home = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  const [{ themeMode }] = useGlobalState();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const [isActiveBlur, setIsActiveBlur] = useState<boolean>(false);

  const handleNavigation = (e: any) => {
    const window = e.currentTarget;
    if (window.scrollY >= 60) {
      setIsActiveBlur(true);
    } else {
      setIsActiveBlur(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleNavigation(e));
  }, []);

  return (
    <Stack>
      <HeaderGlobal
        activeBlur={isActiveBlur}
        onClickSignup={() => jumpToReleventElement("signup", -120)}
      />

      <Stack
        display="flex"
        flexDirection="column"
        sx={{
          width: "100%",
          paddingTop: 18,
          backgroundImage: `url(${themeMode === "light"
            ? IMAGES.MODELO_LIGHT_IMG
            : IMAGES.MODELO_DARK_IMG
            }), url(${themeMode === "light" ? IMAGES.BG2_LIGHT : IMAGES.BG2_DARK
            }), url(${themeMode === "light" ? IMAGES.BG1_LIGHT : IMAGES.BG1_DARK
            })`,
          backgroundSize: `${isXs ? "390px 300px" : "530px 400px"
            }, 100% 140px, cover`,
          backgroundPosition: `50% 360px, 0px ${isXs ? "520px" : "620px"
            }, 0px 0px`,
          backgroundRepeat: "no-repeat, no-repeat, no-repeat",
        }}
      >
        <Stack
          display="flex"
          flexDirection="column"
          alignItems="center"
          height={560}
        >
          <Box mb={2} />
          {themeMode === "light" ? (
            <img
              src={IMAGES.LOGO_LIGHT_IMG}
              alt="logo"
              style={{ width: isXs ? 290 : 360, height: isXs ? 74 : 94 }}
            />
          ) : (
            <img
              src={IMAGES.LOGO_DARK_IMG}
              alt="logo"
              style={{ width: isXs ? 290 : 360, height: isXs ? 74 : 94 }}
            />
          )}

          <Typography variant="body1" color="text.primary">
            A Melhor Decisão Para sua Franquia
          </Typography>

          {/* <h2>{`${scrollDir} ${getDirectionEmoji(scrollDir)}`}</h2> */}
        </Stack>

        <Box mb={10} />
        <HowWorks />
        <Box mb={5} />
      </Stack>

      <Box mb={10} />
      <PotentializeFranchise />

      <Box mb={10} />

      <Stack
        width="100%"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          backgroundImage: `url(${themeMode === "light" ? IMAGES.BG4_LIGHT : IMAGES.BG4_DARK
            })`,
          backgroundSize: `cover`,
          backgroundPosition: `0px 0px`,
          backgroundRepeat: "no-repeat",
          padding: { xs: 1, md: 0 },
          display: "flex",
        }}
      >
        <Box mt={6} />
        <AuthSignup isSignupHome onLogin={() => { navigate(RoutesNames.COMMON.LOGIN) }} />
        <Box mt={6} />
      </Stack>

      <Box mb={10} />
      <Contact />

      <Box mb={20} />
      <Footer />
      <Box mb={10} />
    </Stack>
  );
};
