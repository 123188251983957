import React from "react";

import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { TbChevronLeft } from "react-icons/tb";
import { useMediaQuery } from "@mui/material";
import { useGlobalState } from "context";

interface QuestionTitleProps {
  title: string;
  isTitleWithName?: boolean;
  subtitle?: string;
  titleBack?: string;
  isTitleCentered?: boolean;
  isDisableBack?: boolean;
  onBackClicked: () => void;
}
export function QuestionTitle({
  title,
  isTitleWithName,
  subtitle,
  titleBack = "Voltar",
  isTitleCentered,
  isDisableBack,
  onBackClicked,
}: QuestionTitleProps) {
  const [{ user }] = useGlobalState();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const firstName = `${(user?.fullname || "").charAt(0).toUpperCase()}${(
    user?.fullname || ""
  ).slice(1)}`;

  const titleWithName = isTitleWithName
    ? `${title.charAt(0).toLowerCase()}${title.slice(1)}`
    : title;

  return (
    <Stack
      sx={{
        flexDirection: {
          sx: "column",
          md: "row",
        },
      }}
      alignItems="flex-start"
      alignContent="flex-start"
    >
      <Button
        size="small"
        variant={md ? "text" : "outlined"}
        onClick={onBackClicked}
        startIcon={<TbChevronLeft size={24} />}
        disabled={isDisableBack}
        sx={{
          width: "auto",
          marginBottom: {
            xs: 2,
            md: 0,
          },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        {titleBack}
      </Button>

      <Stack
        alignItems="center"
        flexDirection="column"
        flex={1}
        sx={{
          textAlign: {
            xs: isTitleCentered ? "center" : "start",
            md: isTitleCentered ? "center" : "start",
          },
          alignItems: {
            xs: isTitleCentered ? "center" : "flex-start",
            md: isTitleCentered ? "center" : "flex-start",
          },
          paddingLeft: {
            md: 2,
          },
        }}
      >
        {subtitle && (
          <Typography variant="caption" marginTop={0.7} color='text.secondary' gutterBottom>
            {subtitle}
          </Typography>
        )}
        <Typography
          color='text.primary'
          sx={{
            typography: {
              xs: "h6",
              md: "h5",
            },
          }}
        >
          {isTitleWithName
            ? `${firstName.split(" ")[0]}, ${titleWithName}`
            : titleWithName}
        </Typography>
      </Stack>
    </Stack>
  );
}
