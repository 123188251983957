import { ENDPOINTS } from "../../../constants/endpoint";

import { api } from "..";
interface CreateAssociateProps {
  user_id: number;
  quiz_id: number;
}
export const createQuizAssociate = async (
  data: CreateAssociateProps
): Promise<UserQuiz> => {
  return api
    .post(`${ENDPOINTS.USER_QUIZ}/associate`, data)
    .then((response) => response.data);
};

interface FindQuizProps {
  quiz_id: number;
}
export const findUserQuiz = async ({
  quiz_id,
}: FindQuizProps): Promise<UserQuiz> => {
  return api
    .get(`${ENDPOINTS.USER_QUIZ}/${quiz_id}`)
    .then((response) => response.data);
};

interface UpdateQuizAssociateProps {
  quiz_id: number;
  quizData: Partial<UserQuiz>;
  is_finish: boolean;
  // answers: string;
  // question_id_last_answered: number;
}
export const updateQuizAssociate = async ({
  quiz_id,
  is_finish,
  quizData,
}: UpdateQuizAssociateProps): Promise<UserQuiz> => {
  return api
    .put(`${ENDPOINTS.USER_QUIZ}/${quiz_id}/${is_finish}`, quizData)
    .then((response) => response.data);
};
