import React, { useEffect, useMemo } from "react";

import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";
import { useMediaQuery, useTheme } from "@mui/material";

import { CircularProgress, Grid } from "@mui/material";

interface StepIntroProps {
  onAction: () => void;
}

export function StepIntro({ onAction }: StepIntroProps) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  const imgHeight: Number = useMemo(() => {
    if (isXs) return 240;
    if (isSm) return 320;
    if (isMd) return 420;
    if (isLg) return 440;

    return 0;
  }, [isMd, isSm, isXs, isLg]);

  useEffect(() => {
    setTimeout(() => {
      onAction();
    }, 5000);
  }, [onAction]);

  return (
    <Stack>
      <Grid container justifyContent="center" paddingX={2}>
        <Grid item xs={12} sm={7} md={7} lg={7}>
          <Stack width="100%" height={`${imgHeight}px`}>
            <img
              src="https://franchise-api-a29q.onrender.com/assets/questions/quiz_intro.jpg"
              alt="Quiz"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 20,
              }}
            />
          </Stack>
        </Grid>
      </Grid>

      <Stack mt={3} flexDirection="row" justifyContent="center">
        <CircularProgress color="warning" size={20} />
        <Typography
          variant="body1"
          textAlign="center"
          marginLeft={1}
          color="text.primary"
        >
          Carregando as pergunstas...
        </Typography>
      </Stack>
    </Stack>
  );
}
