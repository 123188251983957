import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useMutation } from "react-query";
import { Form, FormikProvider, useFormik } from "formik";

import { Footer, FormikTextField } from "../../components";

import { ApiService } from "../../services";
import LoadingButton from "@mui/lab/LoadingButton";
import { AxiosError } from "axios";
import { validationSchema } from "./validationSchema";
import { toast } from "react-toastify";
import { Button, useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { RoutesNames } from "constants/routes";
import { useTheme } from "@mui/material";
import { useGlobalState } from "context";
import { IMAGES } from "constants/images";
import ReCAPTCHA from "react-google-recaptcha";


interface MutationProps {
  password: string;
  confirm_password?: string;
}

const INITIAL_VALUES: MutationProps = {
  password: "",
  confirm_password: "",
};

export const ResetPassword = () => {
  const theme = useTheme();
  const { token } = useParams();
  const [{ themeMode }] = useGlobalState();
  const recaptchaRef = React.createRef<any>();
  const navigate = useNavigate();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const isValidGoogleRecaptcha = async () => {
    return true;
    // if (process.env.REACT_APP_NODE_ENV === "development") return true;

    // const responseToken = await recaptchaRef.current.executeAsync();
    // const responseGoogleReCaptcha = await ApiService.Auth.googleReCaptcha({
    //   responseToken,
    // });

    // if (
    //   !responseGoogleReCaptcha.success ||
    //   responseGoogleReCaptcha.score <= 0.4
    // ) {
    //   return false;
    // }
    // return true;
  };

  const formik = useFormik({
    validateOnBlur: false,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidRecaptcha = await isValidGoogleRecaptcha();

      if (!isValidRecaptcha) {
        toast.error("Login bloqueado! Por favor, entre em contato.", {
          autoClose: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }

      mutationResetPassword.mutate({
        password: values.password,
      });
    },
  });

  const mutationResetPassword = useMutation<
    undefined,
    AxiosError,
    MutationProps
  >(
    "resetPassword",
    (data) =>
      ApiService.Auth.resetPassword({
        ...data,
        token: token || "",
      }),
    {
      onSuccess: () => {
        formik.resetForm();
        toast.success("Senha redefinida com sucesso", {
          autoClose: 10000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        navigate(RoutesNames.COMMON.LOGIN);
      },
      onError: (resp: AxiosError) => {
        const data = resp.response?.data as ApiErrorResponse;
        toast.error(data.message, {
          autoClose: 10000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      },
    }
  );

  return (
    <Stack
      style={{
        width: '100%',
        padding: 40,
        minHeight: '100vh',
        backgroundImage: `url(${themeMode === 'light' ? IMAGES.BG1_LIGHT : IMAGES.BG1_DARK})`,
        backgroundSize: `cover`,
        backgroundPosition: `0px 0px`,
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Stack display='flex' alignItems='center' >
        <Stack paddingBottom={6} display='flex' alignItems='center'>
          {themeMode === 'light' ?
            <img
              alt="logo"
              src={IMAGES.LOGO_LIGHT_IMG}
              style={{ width: isXs ? 290 : 360, height: isXs ? 74 : 94 }}
            />
            : <img
              alt="logo"
              src={IMAGES.LOGO_DARK_IMG}
              style={{ width: isXs ? 290 : 360, height: isXs ? 74 : 94 }}
            />}

          <Box mb={5} />

          <Stack
            direction="column"
            padding={5}
            borderRadius={2}
            sx={{
              backgroundColor: theme.palette.background.paper,
              display: "flex",
              flex: 1,
              border: `1px solid ${theme.palette.borderColor.main}`,
              width: {
                xs: 360, sm: 480
              }
            }}
          >
            <Typography variant="h6" textAlign="center" color='text.primary'>
              Redefinir senha
            </Typography>

            <FormikProvider value={formik}>
              <Form>
                <Stack spacing={2} paddingTop={2}>
                  <FormikTextField
                    name="password"
                    label="Nova senha"
                    type="password"
                  />
                  <FormikTextField
                    name="confirm_password"
                    label="Confirmar nova senha"
                    type="password"
                  />
                  <Button
                    onClick={() => navigate(RoutesNames.COMMON.LOGIN)}
                  >
                    Fazer login
                  </Button>

                  <Stack spacing={2} paddingTop={2}>
                    <LoadingButton
                      type="submit"
                      loading={mutationResetPassword.isLoading}
                      loadingPosition="center"
                      variant="contained"
                      size="large"
                    >
                      Salvar
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Form>
            </FormikProvider>
          </Stack>
        </Stack>
        <Box mt={10} />
        <Footer />
      </Stack>

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY_SITE || ""}
      />
    </Stack>
  );


};
