import * as yup from "yup";

export const validationSchemaData = yup.object({
  fullname: yup.string().required("Campo obrigatório"),
  // .min(10, "O nome deve conter ao menos 10 caracteres"),
});

export const validationSchemaDeleteAccount = yup.object({
  reason: yup.string().required("Campo obrigatório"),
});
