import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { BaseDialogConfirm, HeaderTitle, NoContent } from "components";
import { amber, blue, blueGrey, green, grey, red } from "@mui/material/colors";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  TbCircleCheckFilled,
  TbCircleX,
  TbCloudUpload,
  TbFileBroken,
  TbFileFilled,
  TbPlayerPause,
  TbPlayerPlay,
  TbTrash,
} from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { toast } from "react-toastify";
import { convertBites } from "utils/convert";
import { useGlobalState } from "context";
import { Skeleton } from "@mui/material";
import { getPathFileFranchisorData } from "utils/storage";
import { ApiService } from "services";
import { useFirebaseUploader } from "hooks/useFirebaseUploader";
import { setNameToFile } from "utils/file";

interface ConfirmDeleFile {
  name: string;
}

interface UploadProgressPauseCancelProps {
  file: File;
  onFinishUpload: () => void;
  onStartUpload: (isUpload: boolean) => void;
}

export default interface IFile {
  url: string;
  name: string;
}

const VisuallyHiddenInput = styled("input")({
  clipPath: "inset(21% 0 0 0%)",
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  top: 0,
  right: 0,
  whiteSpace: "nowrap",
  backgroundColor: "transparent ",
  cursor: "pointer",
  borderRadius: 16,
  border: "none",
});

const DEFAULT_HEIGHT = 500;

const UploadProgressPauseCancel = ({
  file,
  onFinishUpload,
  onStartUpload,
}: UploadProgressPauseCancelProps) => {
  const [{ user }] = useGlobalState();
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [isUpdateGrid, setIsUpdateGrid] = useState<boolean>(false);

  const path = `${getPathFileFranchisorData(user?.id)}/${setNameToFile(
    file,
    file.name
  )}`;
  const { progress, state, start, pause, resume, cancel } =
    useFirebaseUploader(path);

  useEffect(() => {
    if (!file) return;
    start(file);
  }, [file, start]);

  useEffect(() => {
    switch (state) {
      case "UPLOADING":
        onStartUpload(true);
        break;
      case "COMPLETED":
        if (!isUpdateGrid) {
          onFinishUpload();
          setIsUpdateGrid(true);
          onStartUpload(false);
        }
        break;
      default:
        break;
    }
  }, [isUpdateGrid, onFinishUpload, onStartUpload, state]);

  return (
    <Stack flexDirection="row" alignItems="center">
      {["IDLE", "PAUSED", "UPLOADING"].includes(state) && (
        <>
          <Stack marginRight={1}>
            <CircularProgressWithLabel value={progress} />
          </Stack>

          <Tooltip title={isPaused ? "Iniciar" : "Pausar"}>
            <IconButton
              size="small"
              sx={{ height: 28, width: 28 }}
              onClick={() => {
                if (isPaused) {
                  resume();
                  setIsPaused(false);
                } else {
                  pause();
                  setIsPaused(true);
                }
              }}
            >
              {isPaused && <TbPlayerPlay color={green[500]} />}
              {!isPaused && <TbPlayerPause color={amber[500]} />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancelar">
            <IconButton
              size="small"
              sx={{ height: 28, width: 28 }}
              onClick={cancel}
            >
              <TbCircleX color={red[500]} />
            </IconButton>
          </Tooltip>
        </>
      )}

      {state === "COMPLETED" && (
        <TbCircleCheckFilled size={24} color={green[500]} />
      )}

      {state === "CANCELED" && <TbCircleX size={24} color={red[500]} />}
    </Stack>
  );
};

interface FileItemProps {
  file: File;
  onStartUpload: (isUpload: boolean) => void;
  onFinishUpload: () => void;
  // onRemoveUploadFile: () => void;
}
const FileItem = ({
  file,
  onStartUpload,
  onFinishUpload,
}: // onRemoveUploadFile,
FileItemProps) => {
  return (
    <Stack
      flexDirection="row"
      borderRadius={1}
      alignItems="center"
      sx={{
        // backgroundColor: grey[50],
        padding: 1,
        border: `1px dashed ${grey[200]}`,
      }}
    >
      <Stack
        flex={1}
        flexDirection="row"
        alignItems="center"
        // overflow="hidden"
        // textOverflow="ellipsis"
      >
        <Stack marginRight={1}>
          <TbFileFilled size={24} color={blueGrey[500]} />
        </Stack>
        <Stack>
          <Typography
            variant="body2"
            noWrap
            overflow="hidden"
            textOverflow="ellipsis"
            maxWidth={180}
            color="text.primary"
          >
            {file.name}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {convertBites(file.size)}
          </Typography>
        </Stack>
      </Stack>
      <UploadProgressPauseCancel
        file={file}
        onStartUpload={onStartUpload}
        onFinishUpload={onFinishUpload}
        // onRemoveUploadFile={onRemoveUploadFile}
      />
    </Stack>
  );
};

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" color="primary" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="button"
          component="div"
          color="primary"
          fontSize={10}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export const Data = () => {
  const theme = useTheme();
  const [{ user, themeMode }] = useGlobalState();
  const [confirmDeleteFileName, setConfirmDeleteFileName] = useState<
    ConfirmDeleFile | undefined
  >(undefined);
  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(true);
  const [deleteFileLoading, setDeleteFileLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [fileFromStorageList, setFileFromStorageList] = useState<
    FileFromStorage[] | undefined
  >(undefined);

  const getAllFiles = useCallback(async () => {
    setLoadingFiles(true);
    await ApiService.File.getFiles({
      pathFile: getPathFileFranchisorData(user?.id),
    })
      .then((resp) => {
        setFileFromStorageList(resp);
      })
      .finally(() => {
        setLoadingFiles(false);
      });
  }, [user?.id]);

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const selectedFiles = files as FileList;
    setCurrentFiles([...currentFiles, selectedFiles[0]]);
    event.target.value = "";
  };

  const onFinishUpload = async (file: File) => {
    getAllFiles();
    setIsUploading(false);
  };

  const handleCloseDeleteFile = () => {
    setConfirmDeleteFileName(undefined);
  };
  const handleAgreeDeleteFile = async () => {
    console.log(
      "path: ",
      `${getPathFileFranchisorData(user?.id)}/${confirmDeleteFileName?.name}`
    );

    setDeleteFileLoading(true);
    await ApiService.File.deleteFile({
      pathFile: `${getPathFileFranchisorData(user?.id)}/${
        confirmDeleteFileName?.name
      }`,
    })
      .then(() => {
        toast.success("Excluído com sucesso.", {
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        getAllFiles();
      })
      .catch(() => {
        toast.error("Erro ao excluir o arquivo! Por favor, tente novamente", {
          autoClose: 10000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        setDeleteFileLoading(false);
        setConfirmDeleteFileName(undefined);
      });
  };

  const renderTable = (storageList: FileFromStorage[]) => {
    return (
      <TableContainer
        sx={{
          borderRadius: 2,
          height: DEFAULT_HEIGHT,
          maxHeight: 500,
          // backgroundColor: theme.palette.background.paper,
          // border: `1px solid ${theme.palette.borderColor.main}`,
        }}
      >
        <Table stickyHeader>
          <TableHead sx={{ backgroundColor: grey[50] }}>
            <TableRow>
              <TableCell>Nome do arquivo</TableCell>
              <TableCell align="right">Dada e hora de submissão</TableCell>
              <TableCell align="right">Tamanho</TableCell>
              <TableCell align="right">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {storageList.map((file, fileIndex) => (
              <TableRow
                key={`${fileIndex}-${file.name}`}
                sx={{
                  padding: 0,
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  <Stack flexDirection="row" alignItems="center">
                    <TbFileFilled size={24} color={blueGrey[500]} />
                    <Typography
                      variant="body2"
                      marginLeft={1}
                      marginBottom={0.2}
                    >
                      {file.name}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">
                    {moment(file.lastUpdated).format("DD/MM/YYYY HH:mm:ss")}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">
                    {convertBites(Number(file.size || 0))}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="excluir">
                    <IconButton
                      onClick={() =>
                        setConfirmDeleteFileName({ name: file.name })
                      }
                      size="small"
                    >
                      <TbTrash color={red[500]} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  const renderNoTable = () => (
    <Stack
      justifyContent="center"
      alignItems="center"
      flex={1}
      sx={{
        height: "100%",
        border: `1px solid ${theme.palette.borderColor.main}`,
        borderRadius: 2,
        backgroundColor: theme.palette.background.paper,
        paddingBottom: 4,
        paddingX: 2,
      }}
    >
      <NoContent />
    </Stack>
  );
  const rendeTableLoaging = () => {
    const renderRow = () => (
      <TableRow
        sx={{
          padding: 0,
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell component="th" scope="row">
          <Skeleton
            animation="wave"
            variant="rounded"
            width="100%"
            height={32}
          />
        </TableCell>
        <TableCell align="right">
          <Skeleton
            animation="wave"
            variant="rounded"
            width="100%"
            height={32}
          />
        </TableCell>
        <TableCell align="right">
          <Skeleton
            animation="wave"
            variant="rounded"
            width="100%"
            height={32}
          />
        </TableCell>
        <TableCell align="right">
          <IconButton size="small">
            <Skeleton
              animation="wave"
              variant="circular"
              width={24}
              height={24}
            />
          </IconButton>
        </TableCell>
      </TableRow>
    );

    return (
      <TableContainer
        sx={{
          borderRadius: 2,
          height: DEFAULT_HEIGHT,
          maxHeight: 500,
          border: `1px solid ${theme.palette.borderColor.main}`,
        }}
      >
        <Table stickyHeader>
          <TableHead sx={{ backgroundColor: grey[50] }}>
            <TableRow>
              <TableCell>Nome do arquivo</TableCell>
              <TableCell align="right">Dada e hora de submissão</TableCell>
              <TableCell align="right">Tamanho</TableCell>
              <TableCell align="right">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRow()}
            {renderRow()}
            {renderRow()}
            {renderRow()}
            {renderRow()}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  useEffect(() => {
    getAllFiles();
  }, [getAllFiles]);

  useEffect(() => {
    const handleTabClose = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return (event.returnValue =
        "É possível que as alterações feitas não sejam salvas.");
    };

    if (isUploading) {
      window.addEventListener("beforeunload", handleTabClose);
    }

    return () => window.removeEventListener("beforeunload", handleTabClose);
  }, [isUploading]);

  return (
    <Stack>
      <HeaderTitle title="Dados" />
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {loadingFiles && rendeTableLoaging()}
          {!loadingFiles && (
            <>
              {fileFromStorageList && fileFromStorageList.length > 0
                ? renderTable(fileFromStorageList)
                : renderNoTable()}
            </>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack
            sx={{
              minHeight: DEFAULT_HEIGHT,
              borderRadius: 2,
              padding: 2,
              border: `2px dashed ${blue[200]}`,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <label htmlFor="upload-files">
              <Stack
                justifyContent="center"
                alignItems="center"
                marginBottom={3}
                sx={{
                  padding: 2,
                  paddingX: 4,
                  borderRadius: 2,
                  cursor: "pointer",
                  border: `1px dashed ${blue[100]}`,
                  ...{
                    backgroundColor:
                      themeMode === "light" ? blue[50] : "transparent",
                  },
                  "&:hover": {
                    border: `1px dashed ${blue[500]}`,
                  },
                  position: "relative",
                }}
              >
                <TbCloudUpload size={44} color={blue[500]} />
                <Stack marginTop={1}>
                  <Typography
                    variant="subtitle2"
                    textAlign="center"
                    color="text.secondary"
                  >
                    Selecione um aquivo do seu dipositivo ou arreste e solte um
                    arquivo aqui
                  </Typography>
                </Stack>
                <VisuallyHiddenInput
                  type="file"
                  onChange={selectFile}
                  id="upload-files"
                />
              </Stack>
            </label>

            {currentFiles.length === 0 && (
              <Stack
                gap={1}
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TbFileBroken size={64} color={grey[200]} />
                <Typography
                  variant="body2"
                  gutterBottom
                  width={120}
                  textAlign="center"
                  color="text.secondary"
                >
                  Nenhum arquivo adicionado
                </Typography>
              </Stack>
            )}

            {currentFiles.length > 0 && (
              <>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  color="text.primary"
                >
                  Arquivos em processo de envio
                </Typography>

                <Stack
                  gap={1}
                  sx={{
                    flex: 1,
                    overflowX: "hidden",
                    overflow: "auto",
                    maxHeight: "280px",
                    display: "flex",
                  }}
                >
                  {currentFiles.map((file, fileIndex) => (
                    <FileItem
                      file={file}
                      onStartUpload={(isUpload) => setIsUploading(isUpload)}
                      onFinishUpload={() => onFinishUpload(file)}
                      // onRemoveUploadFile={() => onRemoveUploadFile(fileIndex)}
                    />
                  ))}
                </Stack>
              </>
            )}
          </Stack>
        </Grid>
      </Grid>

      <BaseDialogConfirm
        show={confirmDeleteFileName !== undefined}
        onClose={handleCloseDeleteFile}
        title="Deseja relamete excluir o arquivo?"
        subtitle="Ao confirmar, o arquivo não ficará mais acessivél para análize."
        onAgree={handleAgreeDeleteFile}
        onCancel={handleCloseDeleteFile}
        agreeIsLoading={deleteFileLoading}
        agreeColor="error"
        titleAgree="Excluir"
      />
    </Stack>
  );
};
