import React, { useMemo, useState } from "react";
import {
  TbLogout,
  TbChevronDown,
  TbChevronUp,
  TbMenu2,
  TbX,
  TbMoon,
  TbSun,
} from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { grey, red } from "@mui/material/colors";
import { useGlobalState } from "../../context";
import { HEADER_MENU_USER } from "constants/header-menu-user";
import { HEADER_MENU_NAV } from "constants/header-menu-nav";
import { IMAGES } from '../../constants/images'
import { ID_LOCAL_STORAGE_THEME_MODE } from "constants/cookie-local-storage";

export const Header = () => {
  const theme = useTheme();
  const [{ user, themeMode }, dispatch] = useGlobalState();
  const location = useLocation();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (path?: string) => {
    if (path) navigate(path);
    setAnchorEl(null);
  };

  const handleMenuClick = (path?: string) => {
    if (path) navigate(path);
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch({ type: "SET_LOGOUT" });
    setAnchorEl(null);
  };

  const handleChangeThemeColor = () => {
    if (themeMode === 'light') {
      localStorage.setItem(ID_LOCAL_STORAGE_THEME_MODE, 'dark');
      dispatch({
        type: 'SET_THEME_MODE',
        data: 'dark'
      })
      return
    }

    localStorage.setItem(ID_LOCAL_STORAGE_THEME_MODE, 'light');
    dispatch({
      type: 'SET_THEME_MODE',
      data: 'light'
    })
  }

  const hasMenuNav = useMemo(() => {
    return user?.user_type && HEADER_MENU_NAV[user.user_type].length > 0;
  }, [user?.user_type]);

  return (
    <>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        sx={{
          paddingY: 1,
          paddingX: 2,
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.borderColor.main}`,
          marginBottom: 2,
        }}
      >
        <Stack display='flex' flexDirection="row" alignItems="center" flex={1} >
          {hasMenuNav && (
            <IconButton
              size="small"
              sx={{
                width: 36,
                height: 36,
                marginRight: 1,
                display: {
                  xs: "block",
                  md: "none",
                },
              }}
              onClick={() => setOpenDrawer(!openDrawer)}
            >
              <TbMenu2 size={24} color={themeMode === 'light' ? grey[900] : '#fff'} />
            </IconButton>
          )}
          <Stack marginRight={2} mt={1}>
            {themeMode === 'light' ?
              <img
                src={IMAGES.LOGO_LIGHT_IMG}
                alt="logo"
                style={{ width: 180, height: 44 }}
              />
              : <img
                src={IMAGES.LOGO_DARK_IMG}
                alt="logo"
                style={{ width: 160, height: 42 }}
              />}
          </Stack>
          <Stack
            flexDirection="row"
            justifyContent="start"
            alignItems="center"
            paddingLeft={6}
            gap={4}
            sx={{
              display: {
                xs: "none",
                md: "flex",
              },
            }}
          >
            {user?.user_type &&
              HEADER_MENU_NAV[user.user_type].length > 0 &&
              HEADER_MENU_NAV[user.user_type].map((nav) => {
                const isSelected = nav.path === location.pathname;
                return (
                  <Button
                    key={nav.path}
                    onClick={() => { navigate(nav.path) }}
                    sx={{
                      fontWeight: isSelected ? "bold" : "normal",
                      color: isSelected ? "primary" : grey[500],
                    }}
                  >
                    {nav.title}
                  </Button>

                );
              })}
          </Stack>
        </Stack>

        <Box>
          <Stack>
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{ position: "relative" }}
            >
              <Box
                sx={{
                  position: "absolute",
                  right: -6,
                  top: 16,
                  zIndex: 1,
                  height: 16,
                  width: 16,
                  borderRadius: 100,
                  backgroundColor: grey[900],
                }}
              >
                {open ? (
                  <TbChevronUp size={16} color="white" />
                ) : (
                  <TbChevronDown size={16} color="white" />
                )}
              </Box>
              <Avatar src={IMAGES.ACCOUNT} sx={{ mr: 0.5 }} />
            </IconButton>
          </Stack>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={() => {
              handleClose();
            }}
            onClick={() => {
              handleClose();
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 1px 10px rgba(0,0,0,0.20))",
                mt: 1.5,
                ml: -0.6,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Box pt={1} pl={2} pr={2} mb={3}>
              <Typography color="text.primary" variant="subtitle2">
                {user?.firstname || user?.fullname}
              </Typography>
              <Typography color="text.secondary" variant="body2">
                {user?.email}
              </Typography>
            </Box>

            {HEADER_MENU_USER[user?.user_type!].length > 0 &&
              HEADER_MENU_USER[user?.user_type!].map((menu) => {
                if (menu.isHidden) return null;
                const Icon = menu.icon;

                return (
                  <MenuItem
                    key={menu.path}
                    onClick={() => handleMenuClick(menu.path)}
                  >
                    {Icon && (
                      <ListItemIcon>
                        <Icon size={24} color={theme.palette.text.secondary} />
                      </ListItemIcon>
                    )}
                    <Typography color="text.secondary" variant="subtitle2">
                      {menu.title}
                    </Typography>
                  </MenuItem>
                );
              })}
            <MenuItem onClick={handleChangeThemeColor}>
              <ListItemIcon>
                {themeMode === 'light' && <TbMoon size={24} color={theme.palette.text.secondary} />}
                {themeMode === 'dark' && <TbSun size={24} color={theme.palette.text.secondary} />}
              </ListItemIcon>
              <Typography color="text.secondary" variant="subtitle2">
                {themeMode === 'light' ? 'Dark' : 'Light'}
              </Typography>
            </MenuItem>

            <Divider sx={{ marginBottom: 1 }} />

            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <TbLogout size={24} color={red[500]} />
              </ListItemIcon>
              <Typography color={red[500]} variant="subtitle2">
                Sair
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Stack>

      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(!openDrawer)}
      >
        <Stack sx={{ width: 300, paddingX: 2, paddingY: 2 }}>
          <Stack
            justifyContent="space-between"
            flexDirection="row"
            marginBottom={4}
          >
            <Typography variant="h5">Menu</Typography>
            <IconButton size="small" onClick={() => setOpenDrawer(!openDrawer)}>
              <TbX size={24} />
            </IconButton>
          </Stack>

          <List>
            {user?.user_type &&
              HEADER_MENU_NAV[user.user_type].length > 0 &&
              HEADER_MENU_NAV[user.user_type].map((nav) => {
                const isSelected = nav.path === location.pathname;
                const Icon = nav.icon;
                return (
                  <ListItem disablePadding key={nav.path}>
                    <ListItemButton
                      selected={isSelected}
                      onClick={() => {
                        setOpenDrawer(!openDrawer);
                        navigate(nav.path);
                      }}
                    >
                      {Icon && (
                        <ListItemIcon>
                          <Icon size={24} color={theme.palette.primary.main} />
                        </ListItemIcon>
                      )}
                      <ListItemText primary={nav.title} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </List>
        </Stack>
      </Drawer>
    </>
  );
};
