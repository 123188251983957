import React from "react";
import { useField } from "formik";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectProps } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

export interface FormikSelectFieldMenu {
  valeu: string | number;
  title: string;
}

type FormikSelectFieldProps = {
  name: string;
  fullWidth?: boolean;
  label?: string;
  menuList: FormikSelectFieldMenu[];
} & SelectProps;

export const FormikSelectField = ({
  name,
  fullWidth = true,
  label = "Selecione",
  menuList,
  ...props
}: FormikSelectFieldProps) => {
  const [
    field,
    meta,
    // helpers
  ] = useField(name);

  return (
    <FormControl
      fullWidth={fullWidth}
      error={meta.touched && Boolean(meta.error)}
    >
      <InputLabel id="select-type">{label}</InputLabel>
      <Select
        {...field}
        id={field.name}
        name={name}
        labelId={field.name}
        label={label}
        // helperText={meta.touched ? meta.error : ""}
        error={meta.touched && Boolean(meta.error)}
        value={field.value}
        onChange={field.onChange}
        {...props}
      >
        <MenuItem value="">
          <em>-- Selecione --</em>
        </MenuItem>
        {menuList.map((item) => (
          <MenuItem value={item.valeu}>{item.title}</MenuItem>
        ))}
      </Select>
      {meta.touched && Boolean(meta.error) && (
        <FormHelperText sx={{ color: "#fe494cff !important" }}>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};
