import React from "react";

import Typography from "@mui/material/Typography";

import { Box, Stack } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { ApiService } from "services";
import { toast } from "react-toastify";
import { FormikTextField } from "components";
import { LoadingButton } from "@mui/lab";
import {
  validationSchemaPassword,
  validationSchemaTwoFactorPhone,
  validationSchemaTwoFactorCode,
} from "./validationSchema";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Divider from "@mui/material/Divider";

interface MutationProps {
  password: string;
  new_password: string;
  confirm_new_password: string;
}
interface MutationPropsTwoFactorPhone {
  phone: string;
}
interface MutationPropsTwoFactorCode {
  code: string;
}

const INITIAL_VALUES_PASSWORD: MutationProps = {
  password: "",
  new_password: "",
  confirm_new_password: "",
};
const INITIAL_VALUES_TWO_FACTOR_PHONE: MutationPropsTwoFactorPhone = {
  phone: "",
};
const INITIAL_VALUES_TWO_FACTOR_CODE: MutationPropsTwoFactorCode = {
  code: "",
};

export const Security = () => {
  const [active, setActive] = React.useState<string>("0");
  const formikPassword = useFormik({
    validateOnBlur: false,
    initialValues: INITIAL_VALUES_PASSWORD,
    validationSchema: validationSchemaPassword,
    onSubmit: (values) => mutation.mutate(values),
  });
  const formikTwoFactorPhone = useFormik({
    validateOnBlur: false,
    initialValues: INITIAL_VALUES_TWO_FACTOR_PHONE,
    validationSchema: validationSchemaTwoFactorPhone,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const formikTwoFactorCode = useFormik({
    validateOnBlur: false,
    initialValues: INITIAL_VALUES_TWO_FACTOR_CODE,
    validationSchema: validationSchemaTwoFactorCode,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const mutation = useMutation<any, AxiosError, MutationProps>(
    "updateMePassword",
    (data) =>
      ApiService.Me.updateMePassword({
        password: data.password,
        new_password: data.new_password,
      }),
    {
      onSuccess: () => {
        formikPassword.resetForm();
        toast.success("Senha alterada com sucesso", {
          autoClose: 10000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      },
      onError: (resp) => {
        const data = resp.response?.data as ApiErrorResponse;
        toast.error(data.message, {
          autoClose: 10000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      },
    }
  );

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setActive(newAlignment || "0");
  };

  return (
    <Box marginBottom={6}>
      <Box marginTop={4}>
        <ToggleButtonGroup
          color="primary"
          value={active}
          exclusive
          fullWidth
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value="0">
            <Typography color='text.primary' variant='subtitle2'>
              Senha
            </Typography>
          </ToggleButton>
          <ToggleButton value="1">
            <Typography color='text.primary' variant='subtitle2'>
              Dois fatores
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {active === "0" && (
        <>
          <Box marginTop={4} marginBottom={1}>
            <Typography
              color='text.primary'
              gutterBottom
              sx={{
                typography: {
                  xs: "subtitle1",
                  md: "h4",
                },
              }}
            >
              Alterar senha
            </Typography>
          </Box>

          <FormikProvider value={formikPassword}>
            <Form>
              <Stack spacing={2}>
                <FormikTextField
                  name="password"
                  label="Senha atual"
                  type="password"
                />
                <FormikTextField
                  name="new_password"
                  label="Nova senha"
                  type="password"
                />
                <FormikTextField
                  name="confirm_new_password"
                  label="Confirmar nova senha"
                  type="password"
                />

                <Stack paddingTop={2}>
                  <LoadingButton
                    type="submit"
                    loading={mutation.isLoading}
                    loadingPosition="center"
                    variant="contained"
                    size="large"
                  >
                    Alterar senha
                  </LoadingButton>
                </Stack>
              </Stack>
            </Form>
          </FormikProvider>
        </>
      )}
      {active === "1" && (
        <>
          <Box marginTop={4} marginBottom={1}>
            <Typography
              color='text.primary'
              gutterBottom
              sx={{
                typography: {
                  xs: "subtitle1",
                  md: "h4",
                },
              }}
            >
              Autenticação de dois fator
            </Typography>
            <Typography gutterBottom variant="body2" color="text.secondary">
              Aprimore a segurança de sua conta utilizando seu celular para
              receber um código de autenticação toda vez que usar um novo
              dispositivo para acessar sua conta.
            </Typography>
          </Box>

          <FormikProvider value={formikTwoFactorPhone}>
            <Form>
              <Stack spacing={2}>
                <FormikTextField
                  name="phone"
                  label="DDD + Celular *"
                  type="phone"
                />
                <Stack>
                  <LoadingButton
                    type="submit"
                    loading={mutation.isLoading}
                    loadingPosition="center"
                    variant="outlined"
                    size="large"
                  >
                    Confirmar celular
                  </LoadingButton>
                </Stack>
              </Stack>
            </Form>
          </FormikProvider>

          <Box marginY={5}>
            <Divider />
          </Box>

          <FormikProvider value={formikTwoFactorCode}>
            <Form>
              <Stack spacing={2}>
                <FormikTextField name="code" label="Código *" />
                <Stack>
                  <LoadingButton
                    type="submit"
                    loading={mutation.isLoading}
                    loadingPosition="center"
                    variant="contained"
                    size="large"
                  >
                    Validar código
                  </LoadingButton>
                </Stack>
              </Stack>
            </Form>
          </FormikProvider>
        </>
      )}
    </Box>
  );
};
