import { ENDPOINTS } from "../../../constants/endpoint";

import { api } from "..";

interface SendEmailProps {
  name: string;
  email: string;
  phoneNumber?: string;
  message: string;
}
export const sendEmail = async (data: SendEmailProps) => {
  const dataFormat = {
    full_name: data.name,
    email: data.email,
    phone_number: data.phoneNumber,
    message: data.message,
  };
  return api
    .post(ENDPOINTS.CONTACT, dataFormat)
    .then((response) => response.data);
};
