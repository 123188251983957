import * as yup from "yup";

export const validationSchema = yup.object({
  password: yup
    .string()
    .min(6, "A senha deve conter no mínimo 6 caracteres.")
    .required("Campo obrigatório"),

  confirm_password: yup
    .string()
    .required("Campo obrigatório")
    .oneOf([yup.ref("password")], "As senha não são iguais."),
});
