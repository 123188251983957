import React from "react";
import { useField } from "formik";
import { TextFieldProps } from "@mui/material";
// import { IMaskInput } from "react-imask";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
type FormikTextFieldProps = {
  name: string;
  fullWidth?: boolean;
} & TextFieldProps;

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: string;
}
const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <></>
      // <IMaskInput
      //   {...other}
      //   mask={'(00) 00000-0000'}
      //   definitions={{
      //     "#": /[1-9]/,
      //   }}
      //   inputRef={ref}
      //   onAccept={(value: any) =>
      //     onChange({ target: { name: props.name, value } })
      //   }
      //   overwrite
      // />
    );
  }
);

export const FormikTextFieldMaskPhone = ({
  name,
  label,
  fullWidth = true,
  ...props
}: FormikTextFieldProps) => {
  const [
    field,
    meta,
    // helpers
  ] = useField(name);

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor="formatted-text-mask-input">{label}</InputLabel>
      <OutlinedInput
        {...field}
        fullWidth={fullWidth}
        id={field.name}
        name={field.name}
        label={label}
        // helperText={meta.touched ? meta.error : ""}
        error={meta.touched && Boolean(meta.error)}
        value={field.value || ""}
        onChange={field.onChange}
        // inputComponent={TextMaskCustom as any}
      />
      {meta.touched && Boolean(meta.error) && (
        <FormHelperText sx={{ color: "#fe494cff !important" }}>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};
