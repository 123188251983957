import { RoutesNames } from "constants/routes";

export const getPathHome = (userType: UserTypeKey) => {
  if (userType === "ADMIN") return RoutesNames.ADMIN.HOME;
  if (userType === "FRANCHISOR") return RoutesNames.FRANCHISOR.DATA;
  if (userType === "ASSOCIATE") return RoutesNames.ASSOCIATE.PROFILE;
  if (userType === "CANDIDATE") return RoutesNames.CANDIDATE.PROFILE;

  return "";
};
