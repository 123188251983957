import React from 'react'
import {
  TbChevronsDown,
  TbMoon,
  TbPhone,
  TbSun,
  TbUserCircle,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Grid, Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useTheme } from "@mui/material";
import { ID_LOCAL_STORAGE_THEME_MODE } from 'constants/cookie-local-storage'
import { useGlobalState } from 'context'
import { RoutesNames } from 'constants/routes'

interface HeaderGlobalProps {
  activeBlur: boolean
  onClickSignup: () => void
}
export const HeaderGlobal = ({ activeBlur, onClickSignup }: HeaderGlobalProps) => {
  const navigate = useNavigate()
  const theme = useTheme();
  const [{ themeMode }, dispatch] = useGlobalState();

  const handleChangeThemeColor = () => {
    if (themeMode === 'light') {
      localStorage.setItem(ID_LOCAL_STORAGE_THEME_MODE, 'dark');
      dispatch({
        type: 'SET_THEME_MODE',
        data: 'dark'
      })
      return
    }

    localStorage.setItem(ID_LOCAL_STORAGE_THEME_MODE, 'light');
    dispatch({
      type: 'SET_THEME_MODE',
      data: 'light'
    })
  }

  return (
    <Stack
      alignItems='center'
      position='fixed'
      sx={{
        width: '100%',
        height: 120,
        ...(activeBlur ? {
          backgroundColor: themeMode === 'light' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
          backdropFilter: 'blur(3px)',
          zIndex: 1,
        } : {})

      }}
    >
      <Grid container
        sx={{
          paddingX: {
            xs: 1, sm: 5
          },
        }}
      >
        <Grid md={4} />
        <Grid md={4} display='flex' flexDirection='column' alignItems='center' mt={5}
          sx={{ cursor: 'pointer', marginRight: { xs: 1, sm: 0 } }}
          onClick={onClickSignup}
        >
          <Button
            variant='contained'
            onClick={onClickSignup}
            disableElevation
          >
            Entrar/Criar Conta
          </Button>
          <Box mb={1} />
          <TbChevronsDown size={24} color={theme.palette.primary.main} />
        </Grid>

        <Grid md={4} display='flex' flexDirection='row' justifyContent='flex-end' alignItems='center' pt={1}>
          <IconButton
            onClick={() => {
              navigate(RoutesNames.COMMON.LOGIN)
            }}
            sx={{
              backgroundColor: theme.palette.background.paper,
              padding: 1.5,
              borderRadius: 2
            }}
          >
            <TbUserCircle size={24} color={theme.palette.text.primary} />
          </IconButton>
          <Box mr={2} />
          <IconButton
            sx={{
              backgroundColor: theme.palette.background.paper,
              padding: 1.5,
              borderRadius: 2
            }}
          >
            <TbPhone size={24} color={theme.palette.text.primary} />
          </IconButton>
          <Box mr={2} />
          <IconButton
            sx={{
              backgroundColor: theme.palette.background.paper,
              padding: 1.5,
              borderRadius: 2
            }}
            onClick={handleChangeThemeColor}
          >
            {themeMode === 'light' && <TbMoon size={24} color={theme.palette.text.primary} />}
            {themeMode === 'dark' && <TbSun size={24} color={theme.palette.text.primary} />}
          </IconButton>
        </Grid>
      </Grid>
    </Stack >
  )
}
