import React from "react";

import Typography from "@mui/material/Typography";

import * as S from "./style";

export const TermsAndConditions = () => (
  <S.Wrapper>
    <Typography variant="body2">Terms And Conditions</Typography>
  </S.Wrapper>
);
