import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import StepperComponent from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { TbCheck, TbCircleDot, TbCircleDotFilled } from "react-icons/tb";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material";
import { grey, green } from "@mui/material/colors";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 9,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: green[500],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: green[500],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : grey[200],
    borderTopWidth: 3,
    borderRadius: 10,
  },
}));

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : grey[200],
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: green[500],
    }),
    "& .QontoStepIcon-completedIcon": {
      color: green[500],
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot
      ownerState={{ active }}
      className={className}
      style={{}}
      sx={{
        width: "32px",
        display: "flex",
        justifyContent: "center",
        marginLeft: 1,
      }}
    >
      {completed && (
        <TbCheck size={24} className="QontoStepIcon-completedIcon" />
      )}
      {!completed && !active && <TbCircleDot size={16} />}
      {!completed && active && <TbCircleDotFilled size={16} />}
    </QontoStepIconRoot>
  );
}

interface StepperProps {
  stepperLength: number;
  activeStep: number;
}
export const Stepper = ({ stepperLength, activeStep }: StepperProps) => {
  const [stepArray] = useState<boolean[]>(new Array(stepperLength).fill(true));

  return (
    <StepperComponent
      // alternativeLabel
      activeStep={activeStep}
      connector={<QontoConnector />}
    >
      {stepArray.map((item, index) => (
        <Step
          key={String(index)}
          disabled={index === 1}
          // completed={index === steps.length}
        >
          <StepLabel
            optional
            StepIconComponent={QontoStepIcon}
            sx={{ width: "32px", justifyContent: "center", display: "flex" }}
          />
        </Step>
      ))}
    </StepperComponent>
  );
};
