import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { Footer, Header } from "../../components";
import { Stack } from "@mui/material";

export const LayoutAssociate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Stack
        sx={{
          minHeight: 800,
          paddingBottom: 10,
        }}
      >
        <Outlet />
      </Stack>
      <Stack paddingY={2}>
        <Footer />
      </Stack>
    </>
  );
};
